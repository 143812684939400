import { arrayRequiredRule } from "@/composables/validationRules";import DialogComponent from "@/modules/dot-details/components/PucDocumentsModal.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
import { ADD_RAILROAD_NO_DATA_FOUND } from "../../../constants/displayTexts";
import { RBAC_CONTROLLER } from "../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../stores/userRoleBasedAccess";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    BannerMessage,
    DialogComponent,
  },
  name: "projectInvolvement",
  props: ["dotId"],
  setup(props, { emit }) {
    const route = useRoute();
    const id = route.params.id;
    const projectInvolvementData = ref(null);
    let isFormValid = ref(false);
    const showBannerMessage = ref(false);
    const dialogComponent = ref(null);
    let valueChanged = ref(false);
    const originalValue = ref(null);
    const isInitialised = ref(false);
    const projectInvolvementAccess = ref({ update: false, read: false });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    onMounted(async () => {
      await checkAccess();
      isInitialised.value = false;
      valueChanged.value = false;
      getProjectInvolvementData();
    });
    const checkAccess = async () => {
      const readAccess = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_INVOLVEMENT_PAGE_READ_ACCESS
      );
      const updateAccess = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_INVOLVEMENT_PAGE_CRTUPD_ACCESS
      );
      projectInvolvementAccess.read = readAccess || false;
      projectInvolvementAccess.value.update = updateAccess || false;
    };
    watch(
      () => [projectInvolvementData.value],
      () => {
        if (
          isInitialised.value &&
          (projectInvolvementData.value?.railroadRWAcquired !=
            originalValue.value?.railroadRWAcquired ||
            !stringArraysEqual(
              projectInvolvementData.value?.selectedAppropriationType,
              originalValue.value?.selectedAppropriationType
            ) ||
            projectInvolvementData.value?.utilityInvolvement !=
              originalValue.value?.utilityInvolvement ||
            projectInvolvementData.value?.additionalPennDOTInvolvementDetails !=
              originalValue.value?.additionalPennDOTInvolvementDetails ||
            projectInvolvementData.value?.pucReviewApprovalRequired !=
              originalValue.value?.pucReviewApprovalRequired ||
            projectInvolvementData.value?.pucObjectionsRegistered !=
              originalValue.value?.pucObjectionsRegistered ||
            projectInvolvementData.value?.isProjectLitigation !=
              originalValue.value?.isProjectLitigation ||
            projectInvolvementData.value?.additionalPUCInvolvementDetails !=
              originalValue.value?.additionalPUCInvolvementDetails ||
            !arraysEqual(
              projectInvolvementData.value.railroadInvolvement,
              originalValue.value.railroadInvolvement
            ) ||
            !stringArraysEqual(
              projectInvolvementData.value?.crossingswithPUCinvolvement,
              originalValue.value?.crossingswithPUCinvolvement
            ))
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    const arraysEqual = (a, b) => {
      if (a == null || b == null) return false;
      if (a?.length !== b?.length) return false;
      for (let i = 0; i < a.length; ++i) {
        if (!objectsAreSame(a[i], b[i])) return false;
      }
      return true;
    };
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      let keysToCheck = [
        "enggAuthtoRailroad",
        "selectedWorkTypes",
        "railroadProjectIdentifier",
      ];
      for (let key of keysToCheck) {
        if (key === "selectedWorkTypes") {
          let areEqual = stringArraysEqual(x[key], y[key]);
          if (!areEqual) {
            objectsAreSame = areEqual;
            break;
          }
        } else if (x[key] !== y[key]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    const stringArraysEqual = (a, b) => {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
      a = a.sort((a, b) => a.localeCompare(b));
      b = b.sort((a, b) => a.localeCompare(b));
      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    };
    function getProjectInvolvementData() {
      axios
        .get(CONFIG_ENDPOINT.VIEW_PROJECT_INVOLVEMENT + id)
        .then((res) => {
          const involvementData = res.data;
          let originalInvolvementData = res.data;
          projectInvolvementData.value = setProjectInvolvement(involvementData);
          if (!projectInvolvementData.value.isProjectLitigation) {
            projectInvolvementData.value.isProjectLitigation = "N";
          }
          if (projectInvolvementData.value.isSafteyProject === "Y") {
            projectInvolvementData.value.crossingswithPUCinvolvementAll =
              projectInvolvementData.value.crossingswithPUCinvolvementAll?.map(
                (dot) => {
                  return { ...dot, check: true };
                }
              );
          }
          originalValue.value = JSON.parse(
            JSON.stringify(setProjectInvolvement(originalInvolvementData))
          );
          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const setProjectInvolvement = (list) => {
      let selectedAppropriationType = [];
      list.appropriationTypes.forEach((appropriationType) => {
        if (appropriationType.associationPresent) {
          selectedAppropriationType.push(appropriationType.appropType);
        }
      });
      list = {
        ...list,
        selectedAppropriationType: selectedAppropriationType,
        railroadRWAcquired: list.railroadRWAcquired
          ? list.railroadRWAcquired
          : "N",
        utilityInvolvement: list.utilityInvolvement
          ? list.utilityInvolvement
          : "N",
        pucObjectionsRegistered: list.pucObjectionsRegistered
          ? list.pucObjectionsRegistered
          : "N",
        crossingswithPUCinvolvement: list?.crossingswithPUCinvolvementAll
          ?.filter((val) => val?.check)
          ?.map((val) => val?.dotNumber),
      };
      list.railroadInvolvement.forEach((railroad, index) => {
        let selectedWorkTypes = [];
        railroad.workTypes.forEach((workType) => {
          if (workType.associationPresent) {
            selectedWorkTypes.push(workType.workType);
          }
        });
        list.railroadInvolvement[index] = {
          ...list.railroadInvolvement[index],
          selectedWorkTypes: selectedWorkTypes,
        };
      });
      return list;
    };
    const saveClicked = () => {
      projectInvolvementData.value.appropriationTypes.forEach(
        (appropriateType, index) => {
          projectInvolvementData.value.appropriationTypes[
            index
          ].associationPresent =
            projectInvolvementData.value.selectedAppropriationType?.includes(
              appropriateType.appropType
            );
          if (
            index ===
            projectInvolvementData.value.appropriationTypes.length - 1
          ) {
            delete projectInvolvementData.value.selectedAppropriationType;
          }
        }
      );
      projectInvolvementData.value.railroadInvolvement.forEach(
        (railroad, index) => {
          railroad.workTypes.forEach((workType, workTypeIndex) => {
            projectInvolvementData.value.railroadInvolvement[index].workTypes[
              workTypeIndex
            ].associationPresent = railroad.selectedWorkTypes?.includes(
              workType.workType
            );
          });
          delete projectInvolvementData.value.railroadInvolvement[index]
            .selectedWorkTypes;
        }
      );
      axios
        .post(
          CONFIG_ENDPOINT.VIEW_PROJECT_INVOLVEMENT + id,
          projectInvolvementData.value
        )
        .then((res) => {
          showBannerMessage.value = true;
          emit("BannerUpdate", {
            bannerType: "success",
            bannerMessage: "Saved Successfully.",
            showBanner: showBannerMessage.value,
          });
          window.scrollTo(0, 0);
          isInitialised.value = false;
          valueChanged.value = false;
          getProjectInvolvementData();
          emit("refreshData", true);
        });
    };
    const cancelSave = () => {
      window.scrollTo(0, 0);
      isInitialised.value = false;
      valueChanged.value = false;
      getProjectInvolvementData();
    };
    const hideBanner = () => {
      showBannerMessage.value = false;
      emit("BannerUpdate", {
        bannerType: "success",
        bannerMessage: "Saved Successfully.",
        showBanner: showBannerMessage.value,
      });
    };
    const getCrossingswithPUCinvolvementValue = computed({
      get: () => {
        return projectInvolvementData.value.crossingswithPUCinvolvement?.length
          ? projectInvolvementData.value?.crossingswithPUCinvolvement?.join(
              ", "
            )
          : " ";
      },
    });
    const detailMaxLength = computed({
      get: () => {
        return 500;
      },
    });
    const getPucDocs = (enteredValue) => {
      axios
        .get(
          CONFIG_ENDPOINT.PUCLINKS +
            "?docketNumber=" +
            projectInvolvementData?.value?.pucDocketNumber
        )
        .then((response) => {
          openDialog(
            projectInvolvementData?.value?.pucDocketNumber,
            response.data
          );
        })
        .catch((err) => {
          // writing this invalid scenario if the backend service gives 404
          console.log(err);
        });
    };
    const openDialog = (docketValue, data) => {
      dialogComponent.value.open(docketValue, data).then((resolve) => {
        if (resolve) {
          console.log("resolved");
        }
      });
    };
    const removeItem = (index, field) => {
      projectInvolvementData.value[field].splice(index, 1);
    };
    const removeWorkType = (railroadIndex, index) => {
      projectInvolvementData.value.railroadInvolvement[
        railroadIndex
      ].selectedWorkTypes.splice(index, 1);
    };
    const railroadRWAcquiredUpdate = (event) => {
      if (event === "N") {
        projectInvolvementData.value.selectedAppropriationType = [];
      }
    };
    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          showBannerMessage.value = false;
          emit("BannerUpdate", {
            bannerType: "success",
            bannerMessage: "",
            showBanner: showBannerMessage.value,
          });
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        showBannerMessage.value = false;
        emit("BannerUpdate", {
          bannerType: "success",
          bannerMessage: "",
          showBanner: showBannerMessage.value,
        });
        next();
      }
    });
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    const getRailroadInvolvementList = computed({
      get: () => {
        return projectInvolvementData.value.railroadInvolvement;
      },
    });
    return {
      id,
      projectInvolvementData,
      arrayRequiredRule,
      saveClicked,
      detailMaxLength,
      isFormValid,
      hideBanner,
      showBannerMessage,
      dialogComponent,
      getPucDocs,
      ADD_RAILROAD_NO_DATA_FOUND,
      cancelSave,
      removeItem,
      removeWorkType,
      railroadRWAcquiredUpdate,
      valueChanged,
      getRailroadInvolvementList,
      projectInvolvementAccess,
      getCrossingswithPUCinvolvementValue,
    };
  },
};
