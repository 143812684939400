import useDateField from "@/composables/dateField.js";import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useDocumentStore } from "@/stores/document";
import axios from "axios";
import { reactive, ref, toRefs } from "vue";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  name: "DocumentsVersionDialog",
  components: {
    PrimaryButton,
    SecondaryButton,
    LoadingOverlay,
  },
  props: {
    id: { type: Number, default: null },
    item: Object,
  },
  setup(props) {
    const filesList = ref([]);
    const loading = ref(false);
    const documentStore = useDocumentStore();
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      id: null,
      parentId: null,
      isRailroadConsultantAgreement: null,
      itemIndex: null,
      formattedDate: new Date(),
      formData: null,
    });

    const open = (
      title,
      formData,
      id,
      parentId,
      isRailroadConsultantAgreement
    ) => {
      state.dialog = true;
      state.title = title;
      state.formData = formData;
      state.id = id;
      state.parentId = parentId;
      state.isRailroadConsultantAgreement = isRailroadConsultantAgreement;
      fetchData();
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const closeDialog = () => {
      state.resolve(false);
      state.dialog = false;
    };

    const { getFormattedDateStringNoTime } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    async function fetchData() {
      loading.value = true;
      if (state.parentId) {
        if (state.isRailroadConsultantAgreement) {
          axios
            .get(
              CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_VERSION_LIST +
                state.id +
                "&parentID=" +
                state.parentId
            )
            .then(async (res) => {
              filesList.value = res?.data?.data?.map((data) => {
                return {
                  ...data,
                  documentUploadDate: getDate(data.documentUploadDate),
                };
              });
              console.log("filesList", filesList);
              loading.value = false;
            })
            .catch((err) => {
              filesList.value = [];
              loading.value = false;
            });
        } else {
          if (!(await isNumeric(state.id))) {
            axios
              .get(
                CONFIG_ENDPOINT.GET_VERSIONING_FILE_LIST_DOT +
                  state.id +
                  "&parentID=" +
                  state.parentId
              )
              .then(async (res) => {
                filesList.value = res?.data?.data?.map((data) => {
                  return {
                    ...data,
                    documentUploadDate: getDate(data.documentUploadDate),
                  };
                });
                console.log("filesList", filesList);
                loading.value = false;
              })
              .catch((err) => {
                filesList.value = [];
                loading.value = false;
              });
          } else {
            axios
              .get(
                CONFIG_ENDPOINT.GET_VERSIONING_FILE_LIST +
                  state.id +
                  "&parentID=" +
                  state.parentId
              )
              .then(async (res) => {
                filesList.value = res?.data?.data?.map((data) => {
                  return {
                    ...data,
                    documentUploadDate: getDate(data.documentUploadDate),
                  };
                });
                console.log("filesList", filesList);
                loading.value = false;
              })
              .catch((err) => {
                filesList.value = [];
                loading.value = false;
              });
          }
        }
      } else {
        filesList.value = [];
        loading.value = false;
      }
    }
    const downloadFile = (fileDetails) => {
      let docId = fileDetails.escDocumentId;
      let endpoint = CONFIG_ENDPOINT.DOWNLOAD_SCOPING_FILE;
      documentStore.downloadFile(docId, state.id, endpoint);
    };
    const isNumeric = (s) => {
      return !isNaN(s - parseFloat(s));
    };
    return {
      ...toRefs(state),
      closeDialog,
      open,
      loading,
      filesList,
      downloadFile,
      isNumeric,
    };
  },
};
