<template>
  <div>
    <dynamic-menu
      :menu-items="menuData"
      :menuTitle="menuName"
      @click="$emit('resetError')"
    />
  </div>
</template>
<script>
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import DynamicMenu from "@/modules/shared/components/DynamicMenu.vue";
import { computed, onMounted, ref } from "vue";
import { userRoleBasedAccessStore } from "../../../stores/userRoleBasedAccess";
import { RBAC_CONTROLLER } from "../../../constants/rbacconstants";
export default {
  name: "AdminMenu",
  components: {
    DynamicMenu,
  },
  emits: ["resetError"],
  setup(props) {
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    let flaggerActivityReadAccess = ref(true);
    let signatureTemplateReadAccess = ref(false);
    let signatureTemplateCreateUpdateAccess = ref(false);
    let manageDistrictOfficesReadAccess = ref(false);
    let manageDistrictOfficesCreateUpdateAccess = ref(false);
    let maintainRailroadCompaniesReadAccess = ref(false);
    let maintainRailroadCompaniesUpdateAccess = ref(false);
    let assignDistrictsReadAccess = ref(false);
    let assignDistrictsCreateUpdateAccess = ref(false);
    onMounted(async () => {
      let accessList = [
        "ADDEDIT_FLAGGERACTIVITIES_CRTUPD_ACCESS",
        "TEMPLATE_SIGNATURE_PAGE_READ_ACCESS",
        "TEMPLATE_SIGNATURE_PAGE_CRTUPD_ACCESS",
        "DISTRICT_OFFICE_READ_ACCESS",
        "DISTRICT_OFFICE_CRTUPD_ACCESS",
        "MANAGE_RAILROAD_COMPANIES_READ_ACCESS",
        "MANAGE_RAILROAD_COMPANIES_CRTUPD_ACCESS",
        "ASSIGN_DISTRICTS_READ_ACCESS",
        "ASSIGN_DISTRICTS_CRTUPD_ACCESS",
      ];
      for (let key of accessList) {
        await accessChecks(key);
      }
    });

    const accessChecks = async (key) => {
      let accessResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER?.[key]
      );
      if (key === "TEMPLATE_SIGNATURE_PAGE_READ_ACCESS") {
        signatureTemplateReadAccess.value = accessResponse;
      } else if (key === "DISTRICT_OFFICE_READ_ACCESS") {
        manageDistrictOfficesReadAccess.value = accessResponse;
      } else if (key === "MANAGE_RAILROAD_COMPANIES_READ_ACCESS") {
        maintainRailroadCompaniesReadAccess.value = accessResponse;
      } else if (key === "ASSIGN_DISTRICTS_READ_ACCESS") {
        assignDistrictsReadAccess.value = accessResponse;
      } else if (
        key === "TEMPLATE_SIGNATURE_PAGE_CRTUPD_ACCESS" &&
        !signatureTemplateReadAccess.value
      ) {
        signatureTemplateCreateUpdateAccess.value = accessResponse;
      } else if (
        key === "DISTRICT_OFFICE_CRTUPD_ACCESS" &&
        !manageDistrictOfficesReadAccess.value
      ) {
        manageDistrictOfficesCreateUpdateAccess.value = accessResponse;
      } else if (
        key === "MANAGE_RAILROAD_COMPANIES_CRTUPD_ACCESS" &&
        !maintainRailroadCompaniesReadAccess.value
      ) {
        maintainRailroadCompaniesUpdateAccess.value = accessResponse;
      } else if (
        key === "ASSIGN_DISTRICTS_CRTUPD_ACCESS" &&
        !assignDistrictsReadAccess.value
      ) {
        assignDistrictsCreateUpdateAccess.value = accessResponse;
      } else if (key === "ADDEDIT_FLAGGERACTIVITIES_CRTUPD_ACCESS") {
        flaggerActivityReadAccess.value = accessResponse;
      }
    };
    const menuData = computed(() => {
      let menuList = [
        {
          type: "subheader",
          title: "GCMS & Consultant Admin",
          showMenu: true,
        },
        {
          subtitle: "Maintain Flagger Activity",
          value: CONFIG_ENDPOINT.GCMS_MAIN_ADMIN_MENU,
          showMenu: flaggerActivityReadAccess.value,
        },
        {
          subtitle: "Manage Template Signatures ",
          value: CONFIG_ENDPOINT.MANAGE_SIGNATURE_TEMPLATE_ROUTE,
          showMenu: signatureTemplateReadAccess.value,
        },
        {
          subtitle: "Manage Consulting Companies",
          value: CONFIG_ENDPOINT.MANAGE_CONSULTING_COMPANIES_ROUTE,
          showMenu: signatureTemplateReadAccess.value,
        },
        {
          subtitle: "Consultant Agreement",
          value:
            CONFIG_ENDPOINT.GCMS + CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_URL,
          showMenu:
            assignDistrictsReadAccess.value ||
            assignDistrictsCreateUpdateAccess.value,
        },
        {
          subtitle: "Manage Parent-Subsidiaries",
          value:
            CONFIG_ENDPOINT.GCMS +
            CONFIG_ENDPOINT.MANAGE_PARENT_SUBSIDIARIES_URL,
          showMenu: true,
          /* assignDistrictsReadAccess.value ||
            assignDistrictsCreateUpdateAccess.value, */
        },

        { type: "subheader", title: "District/CO Admin", showMenu: true },
        {
          subtitle: "Manage District Offices",
          value: CONFIG_ENDPOINT.GCMS_MAIN_DISTRICT_OFFICE_LIST,
          showMenu:
            manageDistrictOfficesReadAccess.value ||
            manageDistrictOfficesCreateUpdateAccess.value,
        },
        {
          subtitle: "Maintain Railroad Companies",
          value:
            CONFIG_ENDPOINT.GCMS + CONFIG_ENDPOINT.MANAGE_RAILROAD_COMPANIES,
          showMenu:
            maintainRailroadCompaniesReadAccess.value ||
            maintainRailroadCompaniesUpdateAccess.value,
        },
        {
          subtitle: "Assign Districts",
          value: CONFIG_ENDPOINT.GCMS + CONFIG_ENDPOINT.ASSIGN_DISTRICTS_URL,
          showMenu:
            assignDistrictsReadAccess.value ||
            assignDistrictsCreateUpdateAccess.value,
        },

        { type: "subheader", title: "Project Admin", showMenu: true },
      ];
      return menuList?.filter((menu) => menu?.showMenu);
    });
    return {
      menuName: "Admin",
      menuData,
    };
  },
};
</script>
<style>
@import "@/modules/gcmsmain/dashboard/assets/MyTasks.scss";
</style>
