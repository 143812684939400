import useDateField from "@/composables/dateField.js";
import { validDateFutureDate_Inspections } from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import { useDocumentStore } from "@/stores/document";
import { useProjectServiceStore } from "@/stores/projectService";
import { onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";

export default {
  name: "CoordinationMilestones",
  components: { DatePicker },
  props: ["id", "refreshData"],
  emits: ["saveMilestone", "updateRefreshFlag"],

  setup(props, { emit }, _context) {
    const { getFormattedDateStringNoTime } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const id = ref();
    const documentStore = useDocumentStore();
    const projectServiceStore = useProjectServiceStore();
    const route = useRoute();
    const milestones = ref({});
    const coordinationResponseObject = ref({
      displayName: "contractsBidData",
      checkBoxApproved: false,
      railRoads: [],
    });

    watch(
      () => [props.id],
      () => {
        if (props.id) {
          id.value = props.id;
        }
      },
      { deep: true }
    );

    watch(
      () => [props.refreshData],
      () => {
        if (props.refreshData) {
          valueChanged.value = false;
          fetchData();
          emit("updateRefreshFlag", false);
        }
      },
      { deep: true }
    );
    onMounted(async () => {
      id.value = props.id;
      fetchData.value = props.fetchData;
      await fetchData();
    });

    async function fetchData() {
      let response = await projectServiceStore.fetchCoordinationMilestones(
        route.params.id
      );

      if (response) {
        milestones.value = response.data;
        for (let milestone in milestones.value) {
          if (milestones.value[milestone].approvedDate) {
            milestones.value[milestone].approvedDate = milestones.value[
              milestone
            ].approvedDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
          }
          if (milestones.value[milestone].railRoads) {
            fetchDataHelper(milestone);
          }
        }
      }
      coordinationResponseObject.value = {
        displayName: "contractsBidData",
        checkBoxApproved: false,
        railRoads: [],
      };
    }
    const fetchDataHelper = (milestone) => {
      for (let rail in milestones.value[milestone].railRoads) {
        if (milestones.value[milestone].railRoads[rail].approvedDate) {
          milestones.value[milestone].railRoads[rail].approvedDate =
            milestones.value[milestone].railRoads[rail].approvedDate.replace(
              /(\d\d)\/(\d\d)\/(\d{4})/,
              "$3-$1-$2"
            );
        }
      }
    };
    const isDateValidated = ref(false);
    const checkDateValidation = (value) => {
      isDateValidated.value = value;
    };
    let valueChanged = ref(false);
    const dateChange = (event, index, isRailroad) => {

      if (isRailroad){
        if (event == "") {
          milestones.value["contractsBidData"].railRoads[
            index
          ].approvedDate = null;
          let clone = {
            ...milestones.value["contractsBidData"].railRoads[index],
          };
          constructPostObject(clone, event);
          sendObject(event, "contractsBidData", "");
        } else {
          milestones.value["contractsBidData"].railRoads[
            index
          ].approvedDate = event;
  
          let formatDate = getDate(event);
  
          let clone = {
            ...milestones.value["contractsBidData"].railRoads[index],
          };
          constructPostObject(clone, formatDate);
          sendObject(event, "contractsBidData", "");
        }
      }
      else if (event == "") {
          milestones.value[index].approvedDate = null;
          sendObject(event, index, "");
        } 
      else {
          milestones.value[index].approvedDate = event;
          let temp = getDate(milestones.value[index].approvedDate);
          sendObject(event, index, temp);
        }
      valueChanged.value = true;

      }
    
    onBeforeRouteLeave((to, from, next) => {
      if (valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        next();
      }
    });
    window.onbeforeunload = function () {
      if (valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    const sendObject2 = (event,index,temp) => {

      let object = {}
      if (index == "efiledRWplans") {
        object = {
          eFiledRWplansDate
          : !event ? "" : temp,
        };
        
      } 
      else if (index == "pucRWappropriationApproval") {
        object = {
          pucRWappropriationApprovalDate
: !event ? "" : temp,
        };
        
      } 
      else if (index == "pucRWappropriationRecorded") {
        object = {
          pucRWappropriationRecordedDate
: !event ? "" : temp,
        };
        
      }  
      else if (index == "pucRWNotificationofRecording") {
        object = {
          pucRWnotificationofRecordingDate
: !event ? "" : temp,
        };
      }
      
        emit("saveMilestone", object);


    }


    const sendObject = (event, index, temp) => {

      let object = {};

      if (index == "pucRWappropriationApproval" || index == "pucRWNotificationofRecording" || index == "pucRWappropriationRecorded" || index == "efiledRWplans"){
        sendObject2(event,index,temp)
      }
      else if (index == "pucApplicationEfiled") {
        object = {
          pucApplicationEfiledDate: !event ? "" : temp,
        };
      } else if (index == "initialPucFieldConference") {
        object = {
          initialPucFieldConferenceDate: !event ? "" : temp,
        };
      } else if (index == "pucSecretarialLetter") {
        object = {
          pucSecLetterReceivedDate: !event ? "" : temp,
        };
      }  else if (index == "contractsBidData") {
        object = {
          contractorBidMilestoneData: coordinationResponseObject.value,
        };
    
      }  
     else {
        object = {
          pucOrderReceivedDate: !event ? "" : temp,
        };
      }
      emit("saveMilestone", object);
    };

    const constructPostObject = (temp, event) => {
      let name = temp.displayName;
      let newValue = true;

      for (let index in coordinationResponseObject.value.railRoads) {
        if (
          coordinationResponseObject.value.railRoads[index].displayName == name
        ) {
          let formatDate = getDate(event);
          coordinationResponseObject.value.railRoads[index].approvedDate =
            formatDate;
          newValue = false;
        }
      }

      if (newValue) {
        let formatDate = getDate(event);
        temp.approvedDate = formatDate;
        coordinationResponseObject.value.railRoads.push(temp);
      }
    };

    const download = (docId) => {
      let endpoint = CONFIG_ENDPOINT.DOWNLOAD_SCOPING_FILE;
      let id = route.params.id;
      documentStore.downloadFile(docId, id, endpoint);
    };

    return {
      id,
      milestones,
      validDateFutureDate_Inspections,
      checkDateValidation,
      dateChange,
      getDate,
      download,
      sendObject,
      coordinationResponseObject,
    };
  },
};
