import axios from "axios";import { defineStore } from "pinia";
import { ref } from "vue";
import { CONFIG_ENDPOINT } from "../constants/Endpoints";
export const useRailroadProjectTeamStore = defineStore(
  "railroadProjectTeam",
  () => {
    const LookupData = ref({
      railroadProjectManager: [],
      railroadAgreementCoordinator: [],
      railroadInvoiceCoordinator: [],
      railroadFlaggingAndConstructionCoordinator: [],
    });

    const getLookup = async (project, id) => {
      try {
        const response = await axios.get(
          CONFIG_ENDPOINT.GET_RAILROAD_PROJECT_TEAM_DROPDOWN + id
        );
        console.log("response", response);
        LookupData.value = {
          railroadProjectManager: response.data,
          railroadAgreementCoordinator: response.data,
          railroadInvoiceCoordinator: response.data,
          railroadFlaggingAndConstructionCoordinator: response.data,
        };
        return LookupData.value[project];
      } catch (error) {
        LookupData.value[project] = [];
        return LookupData.value[project];
      }
    };
    return {
      LookupData,
      getLookup,
    };
  }
);
