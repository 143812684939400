import UploadScopingFieldViewDocument from "@/modules/scoping-field-view/components/UploadScopingFieldViewDocument.vue";import DatePicker from "@/modules/shared/components/DatePicker.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useDocumentStore } from "@/stores/document";
import { computed, onMounted, ref, watch } from "vue";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    DatePicker,
    UploadScopingFieldViewDocument: UploadScopingFieldViewDocument,
    DocumentsList,
    LoadingOverlay,
  },
  props: {
    id: { type: Number, default: null },
    title: { type: String, default: "" },
    updateEvent: { default: null },
    isUpdate: { type: Boolean, default: false },
    uploadUpdateConfig: { default: null },
    uploadUpdateApiEvent: { default: null },
  },
  name: "UploadUpdateRailroadDotDocumentDialogSamePage",
  setup(props, { emit }) {
    let isFormValid = ref(false);
    let title = ref(props?.title);
    let id = ref(props?.id);
    let updateEvent = ref(props?.updateEvent);
    let isUpdate = ref(props?.isUpdate);
    let uploadUpdateConfig = ref(props?.uploadUpdateConfig);
    let uploadUpdateApiEvent = ref(props?.uploadUpdateApiEvent);
    const documentStore = useDocumentStore();
    const filesList = ref([]);
    const loading = ref(false);

    onMounted(() => {
      title.value = props?.title;
      id.value = props?.id;
      updateEvent.value = props?.updateEvent;
      isUpdate.value = props?.isUpdate;
      uploadUpdateConfig.value = props?.uploadUpdateConfig;
      uploadUpdateApiEvent.value = props?.uploadUpdateApiEvent;
    });
    const saveData = () => {
      console.log("saveData");
      emit("filesUploaded", true);
    };

    let valueChanged = ref(false);
    const afterModalLeave = () => {
      valueChanged.value = false;
    };
    const valueChangedEvent = (event) => {
      valueChanged.value = event;
    };
    window.onbeforeunload = function () {
      if (valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    let uploadedFiles = ref([]);
    const uploadedFilesEvent = (files) => {
      console.log("uploadedFiles.value = files", files.value);
      uploadedFiles.value = files.value;
      emit("uploadedFilesLength", uploadedFiles.value.length);
    };
    watch(
      () => [
        props?.title,
        props?.id,
        props?.updateEvent,
        props?.isUpdate,
        props?.uploadUpdateConfig,
        props?.uploadUpdateApiEvent,
      ],
      () => {
        title.value = props?.title;
        id.value = props?.id;
        updateEvent.value = props?.updateEvent;
        isUpdate.value = props?.isUpdate;
        uploadUpdateConfig.value = props?.uploadUpdateConfig;
        uploadUpdateApiEvent.value = props?.uploadUpdateApiEvent;
      }
    );
    const isSaveButtonClicked = computed({
      get: () => {
        if (props?.uploadUpdateConfig?.saveClicked) {
          title.value = props?.title;
          id.value = props?.id;
          updateEvent.value = props?.updateEvent;
          isUpdate.value = props?.isUpdate;
          uploadUpdateConfig.value = props?.uploadUpdateConfig;
          uploadUpdateApiEvent.value = props?.uploadUpdateApiEvent;
        }
        return props?.uploadUpdateConfig?.saveClicked;
      },
    });
    const uploadFiles = async (files) => {
      loading.value = true;

      if (uploadedFiles.value?.length) {
        if (isUpdate.value) {
          console.log("event update", updateEvent.value);
          let payload = {
            docType: updateEvent.value.documentType,
            docID: updateEvent.value.escDocumentId,
            dotNumber: updateEvent.value?.dotNumber,
            railRoadName: updateEvent.value?.railRoadName,
            mpmsNumber: props?.id,
          };
          let endpoint = CONFIG_ENDPOINT.UPDATE_VERSION;
          document.getElementById("list-section")?.scrollIntoView();
          await documentStore.uploadDocuments(
            props?.id,
            uploadedFiles.value,
            payload.docType,
            endpoint,
            uploadUpdateConfig.value?.isDynamicDocumentType,
            uploadUpdateConfig.value?.showPreviewRailroadColumn,
            uploadUpdateConfig.value?.showPreviewDOTColumn,
            isUpdate.value,
            payload,
            uploadUpdateConfig.value?.railroadCompanyId,
            uploadUpdateConfig.value?.isRailroadConsultantAgreementId
          );
        } else {
          let docType = uploadUpdateApiEvent.value.docType;
          let endpoint = CONFIG_ENDPOINT.UPLOAD_SCOPING_DOCUMENTS;
          document.getElementById("list-section").scrollIntoView();
          await documentStore.uploadDocuments(
            props?.id,
            uploadedFiles.value,
            docType,
            endpoint,
            uploadUpdateConfig.value?.isDynamicDocumentType,
            uploadUpdateConfig.value?.showPreviewRailroadColumn,
            uploadUpdateConfig.value?.showPreviewDOTColumn,
            isUpdate.value,
            {},
            uploadUpdateConfig.value?.railroadCompanyId,
            uploadUpdateConfig.value?.isRailroadConsultantAgreementId
          );
        }
      }
      loading.value = false;
      saveData();
    };

    return {
      isFormValid,
      saveData,
      open,
      uploadFiles,
      filesList,
      loading,
      afterModalLeave,
      valueChangedEvent,
      valueChanged,
      title,
      id,
      updateEvent,
      isUpdate,
      uploadUpdateConfig,
      uploadUpdateApiEvent,
      isSaveButtonClicked,
      uploadedFilesEvent,
    };
  },
};
