<template>
  <v-row>
    <v-col cols="12" class="ml-20">
      <router-link :to="`/GCMS/${id}`">
        &lt; Return to Project Information Page
      </router-link>
    </v-col>
    <v-col cols="12" class="page-title">
      <h1>Project Involvement</h1>
    </v-col>
    <v-col> </v-col>
  </v-row>
  <div v-if="projectInvolvementData">
    <v-form v-model="isFormValid" class="projectInvolvement">
      <div>
        <div class="env-header mb-20">
          <h2 class="locationHeaderText">PennDOT Involvement</h2>
        </div>
      </div>
      <div class="w-100">
        <v-row class="m-20">
          <v-col cols="3">
            <b>Does Railroad R/W need to be acquired?: </b></v-col
          >
          <v-col cols="3">
            <v-radio-group
              inline
              class="radio-align-top"
              v-model="projectInvolvementData.railroadRWAcquired"
              @update:modelValue="railroadRWAcquiredUpdate"
              :disabled="!projectInvolvementAccess.update"
            >
              <v-radio label="Yes" value="Y"></v-radio>
              <v-radio label="No" value="N"></v-radio> </v-radio-group
          ></v-col>

          <v-col
            cols="2"
            :class="{
              required: projectInvolvementData.railroadRWAcquired === 'Y',
            }"
            ><span><b>Appropriation Type: </b></span></v-col
          >
          <v-col cols="3">
            <v-chip
              v-for="(
                item, index
              ) in projectInvolvementData?.selectedAppropriationType"
              :key="item"
              class="ma-2"
              closable
              :disabled="!projectInvolvementAccess.update"
              @click:close="removeItem(index, 'selectedAppropriationType')"
            >
              {{ item }}
            </v-chip>
            <v-autocomplete
              :disabled="
                projectInvolvementData.railroadRWAcquired === 'N' ||
                !projectInvolvementAccess.update
              "
              v-if="projectInvolvementData.railroadRWAcquired != 'Y'"
              multiple
              v-model="projectInvolvementData.selectedAppropriationType"
              item-title="appropType"
              item-value="appropType"
              :items="projectInvolvementData.appropriationTypes"
            ></v-autocomplete>
            <v-autocomplete
              v-if="projectInvolvementData.railroadRWAcquired === 'Y'"
              :rules="[arrayRequiredRule]"
              multiple
              v-model="projectInvolvementData.selectedAppropriationType"
              item-title="appropType"
              item-value="appropType"
              :items="projectInvolvementData.appropriationTypes"
              :disabled="!projectInvolvementAccess.update"
            ></v-autocomplete>
          </v-col>
          <!-- If Project Type Safety -->
          <v-col cols="3" v-if="projectInvolvementData?.isSafteyProject === 'Y'"
            ><b>Is there Utility Involvement?:</b></v-col
          >
          <v-col
            cols="3"
            v-if="projectInvolvementData?.isSafteyProject === 'Y'"
          >
            <v-radio-group
              class="radio-align-top"
              inline
              v-model="projectInvolvementData.utilityInvolvement"
              :disabled="!projectInvolvementAccess.update"
            >
              <v-radio label="Yes" value="Y"></v-radio>
              <v-radio label="No" value="N"></v-radio> </v-radio-group
          ></v-col>
          <v-col
            cols="6"
            v-if="projectInvolvementData?.isSafteyProject === 'Y'"
          ></v-col>
          <!-- If Project Type Safety -->
          <v-col cols="3"><b>Additional PennDOT Involvement Details:</b></v-col>
          <v-col cols="9"
            ><v-container class="white-textarea comment-box"
              ><v-textarea
                :counter="detailMaxLength"
                :maxlength="detailMaxLength"
                :persistent-counter="true"
                v-model="
                  projectInvolvementData.additionalPennDOTInvolvementDetails
                "
                variant="solo"
                :disabled="!projectInvolvementAccess.update"
              ></v-textarea>
              <span
                >{{
                  detailMaxLength -
                  (projectInvolvementData.additionalPennDOTInvolvementDetails
                    ?.length || 0)
                }}
                characters remaining.</span
              >
            </v-container></v-col
          >
        </v-row>
      </div>
      <div>
        <div class="env-header mb-20">
          <h2 class="locationHeaderText">PUC & Public Involvement</h2>
        </div>
      </div>
      <div class="w-100">
        <v-row class="m-20">
          <v-col cols="3"><b>Is PUC review/approval required?:</b></v-col>
          <v-col cols="3">
            <v-radio-group
              class="radio-align-top"
              inline
              v-model="projectInvolvementData.pucReviewApprovalRequired"
              :rules="[requiredRule]"
              :disabled="!projectInvolvementAccess.update"
            >
              <v-radio label="Yes" value="Y"></v-radio>
              <v-radio label="No" value="N"></v-radio> </v-radio-group
          ></v-col>

          <v-col cols="6"> </v-col>
          <v-col
            v-if="projectInvolvementData.pucReviewApprovalRequired === 'Y'"
            cols="3"
            ><b>Crossings with PUC Involvement:</b></v-col
          >
          <v-col
            v-if="projectInvolvementData.pucReviewApprovalRequired === 'Y'"
            cols="3"
          >
            <v-select
              class="select-fields"
              outlined
              multiple
              :rules="[arrayRequiredRule]"
              v-model="projectInvolvementData.crossingswithPUCinvolvement"
              :items="projectInvolvementData.crossingswithPUCinvolvementAll"
              item-title="dotNumber"
              item-value="dotNumber"
              :disabled="projectInvolvementData.isSafteyProject === 'Y'"
            ></v-select>
          </v-col>
          <v-col
            v-if="projectInvolvementData.pucReviewApprovalRequired === 'Y'"
            cols="1"
          ></v-col>
          <v-col
            v-if="projectInvolvementData.pucReviewApprovalRequired === 'Y'"
            cols="5"
            ><v-card
              ><v-card-text style="background: white">{{
                getCrossingswithPUCinvolvementValue
              }}</v-card-text></v-card
            ></v-col
          >
          <v-col cols="3"
            ><b>Have Objections been registered with the PUC?: </b></v-col
          >
          <v-col cols="3">
            <v-radio-group
              class="radio-align-top"
              :disabled="
                projectInvolvementData.pucReviewApprovalRequired === 'N' ||
                !projectInvolvementAccess.update
              "
              inline
              v-model="projectInvolvementData.pucObjectionsRegistered"
            >
              <v-radio label="Yes" value="Y"></v-radio>
              <v-radio label="No" value="N"></v-radio> </v-radio-group
          ></v-col>
          <!-- If a PUC Docket # has been added to the Project -->
          <v-col
            cols="6"
            align-self="center"
            class="PucDocketView"
            v-if="projectInvolvementData?.pucDocketNumber"
          >
            <span
              class="color-blue cursor-pointer underline"
              @click="getPucDocs()"
              ><b> View PUC Docket Documents</b></span
            >
          </v-col>
          <v-col
            cols="6"
            class="PucDocketView"
            v-if="!projectInvolvementData?.pucDocketNumber"
          >
          </v-col>
          <v-col cols="3"><b>Is Project in Litigation?</b></v-col>
          <v-col cols="9">
            <v-radio-group
              class="radio-align-top"
              inline
              v-model="projectInvolvementData.isProjectLitigation"
              :disabled="!projectInvolvementAccess.update"
              :rules="[requiredRule]"
            >
              <v-radio label="Yes" value="Y"></v-radio>
              <v-radio label="No" value="N"></v-radio> </v-radio-group
          ></v-col>
          <br />
          <!-- If a PUC Docket # has been added to the Project -->
          <v-col cols="3"><b>Additional PUC Involvement Details: </b></v-col>
          <v-col cols="9"
            ><v-container class="white-textarea comment-box"
              ><v-textarea
                :counter="detailMaxLength"
                :maxlength="detailMaxLength"
                :persistent-counter="true"
                variant="solo"
                v-model="projectInvolvementData.additionalPUCInvolvementDetails"
                :disabled="!projectInvolvementAccess.update"
              ></v-textarea>
              <span
                >{{
                  detailMaxLength -
                  (projectInvolvementData.additionalPUCInvolvementDetails
                    ?.length || 0)
                }}
                characters remaining.</span
              >
            </v-container></v-col
          >
        </v-row>
      </div>
      <div>
        <div class="env-header mb-20">
          <h2 class="locationHeaderText"><b>Railroad Involvement</b></h2>
        </div>
      </div>
      <div class="w-100">
        <v-row class="m-20">
          <v-col
            cols="12"
            v-if="!projectInvolvementData?.railroadInvolvement?.length"
          >
            {{ ADD_RAILROAD_NO_DATA_FOUND }}
          </v-col>
        </v-row>
        <v-row
          class="m-20"
          v-if="projectInvolvementData?.railroadInvolvement?.length"
        >
          <v-col cols="12">
            <v-row>
              <v-col cols="3"><b>Railroad</b></v-col>
              <v-col cols="4"><b>Work Type</b></v-col>
              <v-col cols="3"><b>Authorize Engineering?</b></v-col>
              <v-col cols="2"><b>Railroad Project Identifier</b></v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row
              v-for="(railroad, railroadIndex) in getRailroadInvolvementList"
              :key="railroad?.railroadId"
            >
              <v-col cols="3">
                {{ railroad?.railroad }}
              </v-col>
              <v-col cols="4">
                <v-chip
                  v-for="(item, index) in railroad.selectedWorkTypes"
                  :key="item"
                  class="ma-2"
                  closable
                  :disabled="
                    !projectInvolvementAccess.update ||
                    railroad.enggAuthtoRailroad === 'N'
                  "
                  @click:close="removeWorkType(railroadIndex, index)"
                >
                  {{ item }}
                </v-chip>
                <v-autocomplete
                  class="w-50"
                  v-model="railroad.selectedWorkTypes"
                  :disabled="
                    !projectInvolvementAccess.update ||
                    railroad.enggAuthtoRailroad === 'N'
                  "
                  :items="railroad?.workTypes"
                  multiple
                  item-title="workType"
                  item-value="workType"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-radio-group
                  class="radio-align-top"
                  :rules="[requiredRule]"
                  inline
                  v-model="railroad.enggAuthtoRailroad"
                  :disabled="!projectInvolvementAccess.update"
                >
                  <v-radio label="Yes" value="Y"></v-radio>
                  <v-radio label="No" value="N"></v-radio> </v-radio-group
              ></v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="railroad.railroadProjectIdentifier"
                  :disabled="!projectInvolvementAccess.update"
                  :maxlength="20"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-row class="mb-10 ml-20" align="center"> </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="w-100 d-flex justify-content-end mt-20 mb-20">
        <PrimaryButton
          @buttonClicked="saveClicked"
          :buttonText="'Save'"
          :disabled="
            !isFormValid ||
            (projectInvolvementData.railroadRWAcquired === 'Y' &&
              !projectInvolvementData.selectedAppropriationType?.length) ||
            (projectInvolvementData.pucReviewApprovalRequired === 'Y' &&
              !projectInvolvementData?.crossingswithPUCinvolvement?.length) ||
            !valueChanged ||
            !projectInvolvementAccess.update
          "
          class="ml-2"
        ></PrimaryButton>
        <SecondaryButton
          :buttonText="'Cancel'"
          @buttonClicked="cancelSave"
          :disabled="!valueChanged || !projectInvolvementAccess.update"
        ></SecondaryButton>
      </div>
    </v-form>
    <DialogComponent ref="dialogComponent"></DialogComponent>
  </div>
</template>
<script>
import projectInvolvement from "@/modules/manageproject/scripts/projectInvolvement.js";
export default projectInvolvement;
</script>

<style>
@import "@/assets/css/style.scss";
v-field__input {
  overflow: hidden !important;
}
.v-autocomplete .v-field .v-text-field__prefix,
.v-autocomplete .v-field .v-text-field__suffix,
.v-autocomplete .v-field .v-field__input,
.v-autocomplete .v-field.v-field {
  overflow: hidden !important;
}
.radio-align-top {
  margin-top: -8px;
}
</style>
