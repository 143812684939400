<template>
  <v-card-text>
    <AddEditParentSubsidaries></AddEditParentSubsidaries>
  </v-card-text>
</template>
  <script>
import AddEditParentSubsidaries from "@/modules/gcmsmain/admin/submenu/components/AddEditParentSubsidaries.vue";

export default {
  name: "viewAddEditParentSubsidaries-page",
  components: {
    AddEditParentSubsidaries: AddEditParentSubsidaries,
  },
};
</script>
  