<template>
  <v-card class="p-64 v-card-text">
    <v-row>
      <!-- Left corner element -->
      <v-col class="ml-20">
        <router-link :to="`/GCMS/manage-consulting-companies`">
          &lt; Return to Manage Consultant Companies
        </router-link>
      </v-col>
      <!-- Centered element -->
      <v-col class="page-title">
        <h1>Consulting Company</h1>
      </v-col>

      <v-col></v-col>
    </v-row>
    <div class="w-100 d-flex">
      <v-tabs
        class="w-20"
        v-model="tab"
        bg-color="primary"
        direction="vertical"
      >
        <v-tab
          @click="
            $event.preventDefault();
            onTabChange('CompanyInformation');
          "
          value="CompanyInformation"
          >Company Information</v-tab
        >
        <v-tab
          @click="
            $event.preventDefault();
            onTabChange('Users');
          "
          value="Users"
          >Users</v-tab
        >
        <v-tab
          @click="
            $event.preventDefault();
            onTabChange('ConsultantAgreements');
          "
          value="ConsultantAgreements"
          >Consultant Agreements</v-tab
        >
      </v-tabs>

      <v-card-text class="w-80">
        <v-window v-model="tab">
          <v-window-item value="CompanyInformation"
            ><ConsultantCompanyInfo></ConsultantCompanyInfo>
          </v-window-item>

          <v-window-item value="Users">
            <ConsultingCompanyUsers></ConsultingCompanyUsers>
          </v-window-item>

          <v-window-item value="ConsultantAgreements">
            <div class="expanderOverLay">
              <ConsultantAgreement
                :filterType="'consultantCompany'"
              ></ConsultantAgreement>
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>
    </div>
  </v-card>
</template>
  
  <script>
import ConsultingCompany from "@/modules/gcmsmain/admin/submenu/scripts/ConsultingCompany.js";
export default ConsultingCompany;
</script>
  <style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
  