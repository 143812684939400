import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import router from "@/router";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { RBAC_CONTROLLER } from "../../../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../../../stores/userRoleBasedAccess";

export default {
  name: "ParentSubsidaries",
  components: { FeatureTable, OnlineHelpPOC, PrimaryButton },
  props: {
    filterType: { default: "", type: String },
  },
  setup(props) {
    const route = useRoute();
    const id = route.params.id;
    const railroadEstimatesList = ref([]);
    const loading = ref(true);
    let headers = ref([
      {
        title: "Parent Company",
        sortType: "text",
        key: "railroadCompanyName",
        sortable: true,
      },
      {
        title: "Parent Subsidiary",
        sortType: "text",
        key: "subsidiaryRailroadName",
        width: "25%",
        sortable: true,
      },
      {
        title: "Subsidiary Project Team  Default",
        sortType: "text",
        key: "userName",
        sortable: false,
      },
      {
        title: "Action",
        key: "action",
        columnType: "action",
        sortable: false,
        isAction: true,
        width: "20% !important",
        isEdit: true,
        isDelete: true,
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "",
      order: "",
    });
    const isUserEditParentSubsidiaryAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const editRailroadEstimateAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.CONSULTANT_AGRMT_LIST_PAGE_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.CONSULTANT_AGRMT_LIST_PAGE_CRTUPD_ACCESS
      );
      isUserEditParentSubsidiaryAccess.value.read = readResponse || false;
      isUserEditParentSubsidiaryAccess.value.update = updateResponse || false;
    };

    onMounted(async () => {
      await editRailroadEstimateAccessCheck();
      console.log(
        "isUserEditParentSubsidiaryAccess",
        isUserEditParentSubsidiaryAccess.value
      );
      if (isUserEditParentSubsidiaryAccess.value.read) {
        await fetchData();
      }
    });

    async function fetchData() {
      let filterText = "";
      if (sortData.value) {
        filterText =
          filterText +
          "?sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      loading.value = true;
      axios
        .get(CONFIG_ENDPOINT.PARENT_SUBSIDIARIES_LIST + filterText)
        .then((response) => {
          loading.value = false;
          railroadEstimatesList.value = response.data.map((item) => ({
            ...item,
            userName: item.defaultUser?.userName,
            railroadSubsidiaryId: item.railroadSubsidiaryId,
            disableDelete: !isUserEditParentSubsidiaryAccess.value.update,
          }));
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key),
            };
          });
        })
        .catch((err) => {
          loading.value = false;
          railroadEstimatesList.value = [];
        });
    }

    const checkFieldDisabled = (key, filterType) => {
      if (key === "action" || key === "userName") {
        return true;
      }
      if (
        filterType != "auto-complete" ||
        filterType != "multi-select" ||
        filterType != "single-auto-complete"
      ) {
        return false;
      }
      if (
        lookupData.value[key]?.length === 1 &&
        lookupData.value[key][0] === null
      ) {
        return true;
      }
      return !lookupData.value[key]?.length;
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const addButtonClicked = (event) => {
      router.push({
        path: "/GCMS/parent-subsidiaries/add/" + null,
      });
    };
    const editButtonClicked = (event) => {
      console.log(event);
      router.push({
        path:
          "/GCMS/parent-subsidiaries/edit/" + event?.item?.railroadSubsidiaryId,
      });
    };

    const deleteButtonClicked = (event) => {
      router.push({
        path:
          "/GCMS/parent-subsidiaries/delete/" +
          event?.railroadConsultantAgreementId,
      });
    };

    return {
      railroadEstimatesList,
      loading,
      headers,
      sortUpdate,
      editButtonClicked,
      deleteButtonClicked,
      paginationData,
      id,
      route,
      addButtonClicked,
      deleteButtonClicked,
      isUserEditParentSubsidiaryAccess,
    };
  },
};
