import UploadScopingFieldViewDocument from "@/modules/scoping-field-view/components/UploadScopingFieldViewDocument.vue";import DatePicker from "@/modules/shared/components/DatePicker.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useDocumentStore } from "@/stores/document";
import { reactive, ref, toRefs } from "vue";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    DatePicker,
    UploadScopingFieldViewDocument: UploadScopingFieldViewDocument,
    DocumentsList,
    LoadingOverlay,
  },
  props: {
    id: { type: Number, default: null },
  },
  name: "UploadUpdateRailroadDotDocumentDialog",
  setup(props) {
    let isFormValid = ref(false);
    const documentStore = useDocumentStore();
    const filesList = ref([]);
    const loading = ref(false);
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      id: null,
      updateEvent: null,
      isUpdate: null,
      uploadUpdateConfig: null,
      uploadUpdateApiEvent: null,
    });

    const open = (
      title,
      id,
      isUpdate,
      updateEvent,
      uploadUpdateConfig,
      uploadUpdateApiEvent
    ) => {
      state.dialog = true;
      state.title = title;
      state.id = id;
      state.updateEvent = updateEvent;
      state.isUpdate = isUpdate;
      state.uploadUpdateConfig = uploadUpdateConfig;
      console.log("uploadUpdateConfig", updateEvent);
      state.uploadUpdateApiEvent = uploadUpdateApiEvent;
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const saveData = () => {
      state.resolve(true);
      state.dialog = false;
    };

    let valueChanged = ref(false);
    const closeDialog = () => {
      if (valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          state.resolve(true);
          state.dialog = false;
        }
      } else {
        valueChanged.value = false;
        state.resolve(true);
        state.dialog = false;
      }
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
    };
    const valueChangedEvent = (event) => {
      valueChanged.value = event;
    };
    window.onbeforeunload = function () {
      if (valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    const uploadFiles = async (files) => {
      loading.value = true;

      if (state.isUpdate) {
        console.log("event update", state.updateEvent);
        let payload = {
          docType:
            state.uploadUpdateApiEvent
              .docType /* state.updateEvent.documentType */,
          docID: state.updateEvent.escDocumentId,
          dotNumber: state.updateEvent?.dotNumber,
          railRoadName: state.updateEvent?.railRoadName,
          mpmsNumber: state?.id,
        };
        let endpoint = CONFIG_ENDPOINT.UPDATE_VERSION;
        document.getElementById("list-section")?.scrollIntoView();
        await documentStore.uploadDocuments(
          state?.id,
          files,
          payload.docType,
          endpoint,
          state.uploadUpdateConfig?.isDynamicDocumentType,
          state.uploadUpdateConfig?.showPreviewRailroadColumn,
          state.uploadUpdateConfig?.showPreviewDOTColumn,
          state?.isUpdate,
          payload,
          state.uploadUpdateConfig?.railroadCompanyId,
          state.uploadUpdateConfig?.isRailroadConsultantAgreementId
        );
      } else {
        let docType = state.uploadUpdateApiEvent.docType;
        let endpoint = CONFIG_ENDPOINT.UPLOAD_SCOPING_DOCUMENTS;
        document.getElementById("list-section").scrollIntoView();
        await documentStore.uploadDocuments(
          state?.id,
          files,
          docType,
          endpoint,
          state.uploadUpdateConfig?.isDynamicDocumentType,
          state.uploadUpdateConfig?.showPreviewRailroadColumn,
          state.uploadUpdateConfig?.showPreviewDOTColumn,
          state?.isUpdate,
          {},
          state.uploadUpdateConfig?.railroadCompanyId,
          state.uploadUpdateConfig?.isRailroadConsultantAgreementId
        );
      }
      loading.value = false;
      saveData();
    };

    return {
      ...toRefs(state),
      isFormValid,
      saveData,
      closeDialog,
      open,
      uploadFiles,
      filesList,
      loading,
      afterModalLeave,
      valueChangedEvent,
    };
  },
};
