<template>
  <v-card class="p-64 v-card-text">
    <div v-if="SuccessBannerData">
      <BannerMessage
        v-if="
          SuccessBannerData.bannerType === 'success' &&
          SuccessBannerData.showBanner
        "
        :successBanner="true"
        :message="SuccessBannerData.bannerMessage"
        @hideBanner="hideSuccessBanner"
      ></BannerMessage>
    </div>
    <div v-if="ErrorBannerData">
      <BannerMessage
        v-if="
          ErrorBannerData.bannerType === 'error' && ErrorBannerData.showBanner
        "
        :errorBanner="true"
        :message="ErrorBannerData.bannerMessage"
        :hasList="ErrorBannerData.hasList"
        :errorList="ErrorBannerData.errorList"
        @hideBanner="hideErrorBannerMessage"
      ></BannerMessage>
    </div>
    <div class="linkedUserError" v-if="isLinkedUserWarning">
      <div>
        You have been matched to an existing Railroad in GCMS. Please
        review/verify the Railroad details and your Contact record. Please
        update the record and save your changes. If no change is necessary,
        close the window.
      </div>
    </div>
    <br />
    <v-row>
      <!-- Left corner element -->
      <v-col class="ml-20">
        <router-link :to="`/GCMS/manage-railroad-companies`">
          &lt; Return to Maintain Railroad Companies
        </router-link>
      </v-col>
      <!-- Centered element -->
      <v-col class="page-title" v-if="tab == 'RailroadInformation'">
        <h1>{{ toTitleCase(addOrEdit) }} Railroad Company</h1>
      </v-col>

      <v-col class="page-title" v-else-if="tab == 'Contacts'">
        <h1>Contacts</h1>
      </v-col>

      <v-col class="mb-20">
        <OnlineHelpPOC
          :helpKey="tab == 'RailroadInformation' ? 'ADRRCMYHLP' : 'RRCNTHLP'"
        ></OnlineHelpPOC
      ></v-col>
    </v-row>
    <div class="w-100 d-flex">
      <v-tabs
        class="w-20"
        v-model="tab"
        bg-color="primary"
        direction="vertical"
      >
        <v-tab
          @click="
            $event.preventDefault();
            onTabChange('RailroadInformation');
          "
          value="RailroadInformation"
          >Railroad Information</v-tab
        >
        <v-tab
          @click="
            $event.preventDefault();
            onTabChange('Regions');
          "
          :disabled="addOrEdit === 'add' && !savedButtonClicked"
          value="Regions"
          >Regions</v-tab
        >
        <v-tab
          @click="
            $event.preventDefault();
            onTabChange('Contacts');
          "
          :disabled="addOrEdit === 'add' && !savedButtonClicked"
          value="Contacts"
          >Contacts</v-tab
        >
        <v-tab
          @click="
            $event.preventDefault();
            onTabChange('ConsultantAgreements');
          "
          :disabled="addOrEdit === 'add' && !savedButtonClicked"
          value="ConsultantAgreements"
          >Consultant Agreements</v-tab
        >
      </v-tabs>

      <v-card-text class="w-80">
        <v-window v-model="tab">
          <v-window-item value="RailroadInformation"
            ><RailroadCompanyInformationForm
              @BannerUpdate="BannerUpdate"
              @saveClicked="saveClicked"
            ></RailroadCompanyInformationForm>
          </v-window-item>

          <v-window-item value="Regions">
            Regions will be added soon...
          </v-window-item>

          <v-window-item value="Contacts">
            <ContactInfo
              @BannerUpdate="BannerUpdate"
              @saveClicked="saveClicked"
            ></ContactInfo>
          </v-window-item>
          <v-window-item value="ConsultantAgreements">
            <div class="expanderOverLay">
              <ConsultantAgreement
                :filterType="'railroadCompanyName'"
              ></ConsultantAgreement>
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import AddUpdateRailroadCompany from "@/modules/gcmsmain/admin/submenu/scripts/AddUpdateRailroadCompany.js";
export default AddUpdateRailroadCompany;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
