import {
  arrayRequiredRule,
  onlyFutureDateAccepted,
  requiredRule,
} from "@/composables/validationRules";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import axios from "axios";
import { onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { AUTO_COMPLETE_NO_DATA } from "../../../../../constants/displayTexts";
import { CONFIG_ENDPOINT } from "../../../../../constants/Endpoints";
import { RBAC_CONTROLLER } from "../../../../../constants/rbacconstants";
import router from "../../../../../router";
import { userRoleBasedAccessStore } from "../../../../../stores/userRoleBasedAccess";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    BannerMessage,
    LoadingOverlay,
  },
  name: "AddEditParentSubsidaries",
  setup(props) {
    const loading = ref(false);
    const isFormValid = ref(false);
    const route = useRoute();
    const isAdd = ref(route.params.type === "add");
    const isEdit = ref(route.params.type === "edit");
    const id = ref(
      route.params.id && route.params.id != "null" ? route.params.id : ""
    );
    const subsidiariesDetails = ref();
    const valueChanged = ref(false);
    const isInitialised = ref(false);
    const originalParentSubsidaryForm = ref({
      parentCompany: "",
      subsidiary: "",
      subsidiaryUsers: [],
      isAllSelected: false,
      projectTeamDefault: "",
    });
    const parentSubsidaryForm = ref({
      parentCompany: "",
      subsidiary: "",
      subsidiaryUsers: [],
      isAllSelected: false,
      projectTeamDefault: "",
    });
    const parentSubsidaryLookup = ref({
      parentCompany: [],
      subsidiary: [],
      subsidiaryUsers: [],
      projectTeamDefault: [],
    });
    const parentSubsidaryQuery = ref({
      parentCompany: "",
      subsidiary: "",
    });
    const isUserEditParentSubsidiaryAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const accessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.CONSULTANT_AGRMT_LIST_PAGE_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.CONSULTANT_AGRMT_LIST_PAGE_CRTUPD_ACCESS
      );
      isUserEditParentSubsidiaryAccess.value.read = readResponse || false;
      isUserEditParentSubsidiaryAccess.value.update = updateResponse || false;
    };
    onMounted(async () => {
      await accessCheck();
      isAdd.value = route.params.type === "add";
      isEdit.value = route.params.type === "edit";
      console.log("route.params", route.params, isEdit.value);
      if (id.value) {
        isInitialised.value = false;
        if (isUserEditParentSubsidiaryAccess.value.read) {
          loading.value = true;
          fetchParentSubsidiariesData();
        }
      } else {
        loading.value = false;
        isInitialised.value = true;
      }
    });
    const fetchParentSubsidiariesData = () => {
      isInitialised.value = false;
      valueChanged.value = false;

      if (route.params.id && route.params.id != "null") {
        id.value = route.params.id;
      }
      axios
        .get(CONFIG_ENDPOINT.PARENT_SUBSIDIARIES_GET_BY_ID + id.value)
        .then((res) => {
          setEditData(res?.data?.data);
          subsidiariesDetails.value = res?.data?.data;
        })
        .catch((err) => {
          loading.value = false;
        });
    };

    const setEditData = async (data) => {
      parentSubsidaryQuery.value.parentCompany = data?.railroadCompanyName;
      parentSubsidaryQuery.value.subsidiary = data?.subsidiaryRailroadName;
      await autoComplete(
        "parentCompany",
        parentSubsidaryQuery.value.parentCompany
      );
      await autoComplete("subsidiary", parentSubsidaryQuery.value.subsidiary);
      parentSubsidaryForm.value.parentCompany =
        data?.railroadCompanyId || data?.railroadCompanyName;
      if (
        !parentSubsidaryLookup.value.subsidiary?.length &&
        data?.subsidiaryRailroadId &&
        data?.subsidiaryRailroadName
      ) {
        parentSubsidaryLookup.value.subsidiary = [
          {
            railroadCompanyId: data?.subsidiaryRailroadId,
            name: data?.subsidiaryRailroadName,
          },
        ];
      }
      parentSubsidaryForm.value.subsidiary = parentSubsidaryLookup.value
        .subsidiary?.length
        ? data?.subsidiaryRailroadId
        : "";
      await valueSelected("parentCompany");

      await valueSelected("subsidiary");
      console.log("parentSubsidaryForm", parentSubsidaryForm.value);
      parentSubsidaryForm.value.isAllSelected = data?.allRailroadUser === "Y";
      parentSubsidaryForm.value.subsidiaryUsers =
        data?.allRailroadUser === "Y"
          ? parentSubsidaryLookup.value.subsidiaryUsers?.map(
              (user) => user?.userId
            )
          : data?.authorizedUsers?.map((user) => user?.userId);
      parentSubsidaryForm.value.projectTeamDefault =
        data?.defaultUser?.userId || "";

      originalParentSubsidaryForm.value = {
        ...JSON.parse(JSON.stringify(parentSubsidaryForm.value)),
      };
      isInitialised.value = true;
      loading.value = false;
    };
    watch(
      () => [parentSubsidaryForm.value],
      () => {
        console.log("parentSubsidaryForm", parentSubsidaryForm.value);
        if (
          isInitialised.value &&
          !objectsAreSame(
            originalParentSubsidaryForm.value,
            parentSubsidaryForm.value
          )
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );

    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        next();
      }
    });
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    const arraysEqual = (a, b) => {
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
      for (let i = 0; i < a.length; ++i) {
        if (!b.includes(a[i])) return false;
      }
      return true;
    };
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      for (let propertyName in x) {
        if (propertyName === "subsidiaryUsers") {
          if (!arraysEqual(x[propertyName], y[propertyName])) {
            objectsAreSame = false;
            break;
          }
        } else if (x[propertyName] !== y[propertyName]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    const autoComplete = async (field, value = "") => {
      parentSubsidaryQuery.value[field] = value;
      if (
        (field === "parentCompany" || field === "subsidiary") &&
        parentSubsidaryQuery.value?.[field]
      ) {
        console.log("autocompleted value", field, value);
        await fetchLookup(field, parentSubsidaryQuery.value?.[field], false);
      } else if (
        (field === "subsidiary" || field === "parentCompany") &&
        !parentSubsidaryForm.value?.[field]
      ) {
        console.log("autocompleted value else", field, value);
        parentSubsidaryQuery.value[field] = "";
        parentSubsidaryLookup.value[field] = [];
        parentSubsidaryForm.value[field] = "";
      }
    };
    const valueSelected = async (field) => {
      if (field === "parentCompany") {
        let selectedsubsidiary = parentSubsidaryLookup.value[field]?.filter(
          (subsidiaryUsers) => {
            return (
              subsidiaryUsers.railroadCompanyId ===
              parentSubsidaryForm.value?.[field]
            );
          }
        )?.[0];
        if (selectedsubsidiary) {
          await axios
            .post(CONFIG_ENDPOINT.PARENT_SUBSIDIARIES_LIST_SUBSIDIARY_USERS, {
              feinId: selectedsubsidiary?.feinId,
            })
            .then((res) => {
              parentSubsidaryForm.value["subsidiaryUsers"] =
                res.data.data?.length === 1 ? res.data.data : [];
              parentSubsidaryLookup.value["subsidiaryUsers"] =
                res.data.data || [];
            })
            .catch((err) => {
              parentSubsidaryForm.value["subsidiaryUsers"] = [];
              parentSubsidaryLookup.value["subsidiaryUsers"] = [];
            });
        }
      } else if (field === "projectScope") {
        parentSubsidaryForm.value["selectedProjects"] = [];
      }
    };
    const fetchLookup = async (field, searchText, isClear = false) => {
      if (field === "parentCompany") {
        if (searchText && !isClear) {
          await axios
            .post(CONFIG_ENDPOINT.PARENT_SUBSIDIARIES_LIST_PARENT_COMPANY, {
              railroadCompanyName: searchText,
            })
            .then((res) => {
              parentSubsidaryLookup.value[field] =
                res.data.data || res.data || [];
            })
            .catch((err) => {
              parentSubsidaryLookup.value[field] = [];
            });
        } else if (isClear) {
          parentSubsidaryLookup.value[field] = [];
        }
      } else if (field === "subsidiary") {
        if (searchText) {
          await axios
            .post(CONFIG_ENDPOINT.PARENT_SUBSIDIARIES_LIST_SUBSIDIARY, {
              railroadCompanyName: searchText,
            })
            .then((res) => {
              parentSubsidaryLookup.value[field] =
                res.data.data || res.data || [];
            })
            .catch((err) => {
              parentSubsidaryLookup.value[field] = [];
            });
        }
      }
    };
    const selectAllUserUpdate = (event) => {
      if (parentSubsidaryForm.value.isAllSelected) {
        parentSubsidaryForm.value.subsidiaryUsers =
          parentSubsidaryLookup.value.subsidiaryUsers?.map(
            (user) => user?.userId
          );
      } else {
        parentSubsidaryForm.value.subsidiaryUsers = [];
      }
    };
    watch(
      () => [parentSubsidaryForm.value.subsidiaryUsers],
      () => {
        if (
          parentSubsidaryLookup.value.subsidiaryUsers?.length > 0 &&
          parentSubsidaryForm.value.subsidiaryUsers?.length ===
            parentSubsidaryLookup.value.subsidiaryUsers?.length
        ) {
          parentSubsidaryForm.value.isAllSelected = true;
        } else if (!parentSubsidaryLookup.value.subsidiaryUsers?.length) {
          parentSubsidaryForm.value.isAllSelected = false;
        } else {
          parentSubsidaryForm.value.isAllSelected = false;
        }
        let filteredProjectTeamDefault =
          parentSubsidaryLookup.value?.subsidiaryUsers?.filter((user) =>
            parentSubsidaryForm.value?.subsidiaryUsers?.includes(user?.userId)
          );
        parentSubsidaryLookup.value.projectTeamDefault = [
          { userId: "", userName: "-Select Default User-" },
          ...filteredProjectTeamDefault,
        ];
        if (
          parentSubsidaryForm.value.projectTeamDefault &&
          !parentSubsidaryForm.value.subsidiaryUsers.includes(
            parentSubsidaryForm.value.projectTeamDefault
          )
        ) {
          parentSubsidaryForm.value.projectTeamDefault = "";
        }
      },
      {
        deep: true,
      }
    );
    const clearParentCompany = async (event) => {
      parentSubsidaryForm.value.parentCompany = "";
      parentSubsidaryLookup.value.parentCompany = [];
      parentSubsidaryQuery.value.parentCompany = "";
      parentSubsidaryLookup.value.subsidiaryUsers = [];
      parentSubsidaryForm.value.subsidiaryUsers = "";
      parentSubsidaryLookup.value.projectTeamDefault = [];
      parentSubsidaryForm.value.projectTeamDefault = "";
      setTimeout(async () => {
        await fetchLookup(
          "parentCompany",
          parentSubsidaryQuery.value?.["parentCompany"],
          true
        );
      }, 2000);
    };
    const cancelSave = () => {
      isInitialised.value = false;
      valueChanged.value = false;
      router.push({
        path: "/GCMS/parent-subsidiaries",
      });
    };
    const saveClicked = () => {
      console.log("formValue", parentSubsidaryForm.value);
      const payload = {
        railroadCompanyId: parentSubsidaryForm.value.parentCompany,
        railroadSubsidiaryId: isEdit.value ? Number(id.value) : null,
        subsidiaryRailroadId: parentSubsidaryForm.value.subsidiary,
        allRailroadUser: parentSubsidaryForm.value.isAllSelected ? "Y" : "N",
        defaultUser: {
          userId: parentSubsidaryForm.value.projectTeamDefault,
        },
        authorizedUsers:
          parentSubsidaryLookup.value.subsidiaryUsers?.filter((user) =>
            parentSubsidaryForm.value.subsidiaryUsers?.includes(user?.userId)
          ) || [],
      };
      console.log("isEdit.value", isEdit.value);
      if (isEdit.value) {
        axios
          .put(CONFIG_ENDPOINT.PARENT_SUBSIDIARIES_EDIT, payload)
          .then((res) => {
            console.log("user added");
            cancelSave();
          })
          .catch((err) => {
            console.log("err");
          });
      } else {
        axios
          .post(CONFIG_ENDPOINT.PARENT_SUBSIDIARIES_ADD_SUBSIDIARY, payload)
          .then((res) => {
            console.log("user added");
            cancelSave();
          })
          .catch((err) => {
            console.log("err");
          });
      }
    };
    return {
      isAdd,
      loading,
      isFormValid,
      parentSubsidaryForm,
      parentSubsidaryLookup,
      parentSubsidaryQuery,
      autoComplete,
      valueSelected,
      arrayRequiredRule,
      onlyFutureDateAccepted,
      requiredRule,
      selectAllUserUpdate,
      clearParentCompany,
      cancelSave,
      saveClicked,
      AUTO_COMPLETE_NO_DATA,
      isUserEditParentSubsidiaryAccess,
    };
  },
};
