import useDateField from "@/composables/dateField.js";import {
  arrayRequiredRule,
  onlyFutureDateAccepted,
  requiredRule,
} from "@/composables/validationRules";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import CurrencyInput from "@/modules/shared/components/CurrencyInput.vue";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import ReturnReasonModal from "@/modules/shared/components/ReturnReasonModal.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import DialogComponent from "@/modules/shared/components/UploadUpdateRailroadDotDocumentDialog.vue";
import UploadUpdateRailroadDotDocumentSamePage from "@/modules/shared/components/UploadUpdateRailroadDotDocumentSamePage.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import router from "@/router";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { MAX_DATE } from "../../../../../constants/dates";
import { AUTO_COMPLETE_NO_DATA } from "../../../../../constants/displayTexts";
import { CONFIG_ENDPOINT } from "../../../../../constants/Endpoints";
import { RBAC_CONTROLLER } from "../../../../../constants/rbacconstants";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    BannerMessage,
    CurrencyInput,
    DialogComponent,
    DocumentsList,
    DatePicker,
    VueAutoComplete,
    LoadingOverlay,
    UploadUpdateRailroadDotDocumentSamePage,
    ReturnReasonModal,
  },
  name: "EditAddConsultantAgreement",
  props: ["dotId"],
  setup(props) {
    const loading = ref(false);
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    let isFormValid = ref(false);
    const route = useRoute();
    const displayEndDate = ref("");
    const isAdd = route.params.type === "add";
    const isReview = route.params.type === "review";
    const id = ref(
      route.params.railroadConsultantAgreementId &&
        route.params.railroadConsultantAgreementId != "null"
        ? route.params.railroadConsultantAgreementId
        : ""
    );
    const minDate = ref();
    const maxDate = ref();
    const uploadConfigs = ref({
      uploadUpdateConfig: {
        isDynamicDocumentType: false,
        staticDocumentTypeValue: "Consultant Agreement",
        previewDocTypeUrl: "CONSULTANT_AGREEMENT_DOCUMENTS_DOCTYPE_LIST",
        mpmsId: id.value,
        isRailroadConsultantAgreementId: true,
      },
      title: "View/Upload Railroad Estimate Documents",
      id: id.value,
      isUpdate: false,
      updateEvent: null,
      uploadUpdateApiEvent: { docType: "Consultant Agreement" },
    });
    const consultantAgreementQuery = ref({
      railroadName: "",
      consultantCompany: "",
      selectedProjects: "",
      projectScope: "",
    });
    const consultantAgreementLookup = ref({
      railroadName: [],
      consultantCompany: [],
      selectedProjects: [],
      projectScope: [],
    });
    const consultantAgreementForm = ref({
      railroadName: "",
      railroadCompanyId: null,
      consultantCompany: "",
      selectedProjects: [],
      consultant: [],
      projectScope: "",
      endDate: null,
      isActive: "Y",
      status: "",
    });
    const consultantDetails = ref();
    const uploadUpdateDocumentsRef = ref();
    const returnReasonModalRef = ref();
    const valueChanged = ref(false);
    const isInitialised = ref(false);
    const originalConsultantAgreementForm = ref({
      railroadName: "",
      railroadCompanyId: null,
      consultantCompany: "",
      selectedProjects: [],
      consultant: [],
      projectScope: "",
      endDate: null,
      isActive: "Y",
      status: "",
    });
    const buttonClicked = ref("");
    const dateChange = (event) => {
      if (event?.target?.value) {
        consultantAgreementForm.value.endDate = event.target.value;
      } else if (event) {
        consultantAgreementForm.value.endDate = event;
      }
    };
    const isDateValidated = ref(false);
    const checkDateValidation = (value) => {
      isDateValidated.value = value;
    };
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const isConsultantAgreementAccess = ref({
      read: false,
      update: false,
    });
    const clearConsulatantCompany = async () => {
      consultantAgreementForm.value.consultantCompany = "";
      consultantAgreementLookup.value.consultantCompany = [];
      consultantAgreementQuery.value.consultantCompany = "";
      consultantAgreementLookup.value.consultant = [];
      consultantAgreementForm.value.consultant = "";
      await fetchLookup(
        "consultantCompany",
        consultantAgreementQuery.value?.["consultantCompany"]
      );
    };
    const returnValue = ref("");
    const disableFields = ref("");
    watch(
      () => [route.params.railroadConsultantAgreementId],
      () => {
        if (
          route.params.railroadConsultantAgreementId &&
          route.params.railroadConsultantAgreementId != "null"
        ) {
          id.value = route.params.railroadConsultantAgreementId;
        }
      }
    );
    const accessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_CRTUPD_ACCESS
      );
      isConsultantAgreementAccess.value.read = readResponse || false;
      isConsultantAgreementAccess.value.update = updateResponse || false;
    };
    onMounted(async () => {
      await accessCheck();
      maxDate.value = new Date(MAX_DATE).toLocaleDateString("fr-ca");
      minDate.value = new Date().toLocaleDateString("fr-ca");
      if (id.value) {
        isInitialised.value = false;
        if (isConsultantAgreementAccess.value.read) {
          loading.value = true;
          fetchConsultantAgreementData();
        }
      } else {
        loading.value = false;
        isInitialised.value = true;
      }
      const queryParams = router.currentRoute.value.query;
      if (
        queryParams?.key === "railroadCompanyName" &&
        queryParams?.value?.length
      ) {
        disableFields.value = "railroadCompanyName";
        consultantAgreementQuery.value.railroadName = queryParams?.search?.[0];
        await autoComplete(
          "railroadName",
          consultantAgreementQuery.value.railroadName
        );
        consultantAgreementForm.value.railroadName = Number(
          queryParams?.value?.[0]
        );
        await valueSelected("railroadName");
      } else if (
        queryParams?.key === "consultantCompany" &&
        queryParams?.value?.length
      ) {
        disableFields.value = "consultantCompany";
        consultantAgreementQuery.value.consultantCompany =
          queryParams?.value?.[0];
        await autoComplete(
          "consultantCompany",
          consultantAgreementQuery.value.consultantCompany
        );
        consultantAgreementForm.value.consultantCompany =
          consultantAgreementLookup.value["consultantCompany"]?.[0]?.orgID;
        await valueSelected("consultantCompany");
      } else {
        disableFields.value = "";
      }
    });
    const fetchConsultantAgreementData = () => {
      isInitialised.value = false;
      valueChanged.value = false;

      if (
        route.params.railroadConsultantAgreementId &&
        route.params.railroadConsultantAgreementId != "null"
      ) {
        id.value = route.params.railroadConsultantAgreementId;
      }
      axios
        .get(CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_GET_BY_ID + id.value)
        .then((res) => {
          setEditData(res?.data?.data);
          consultantDetails.value = res?.data?.data;
        })
        .catch((err) => {
          loading.value = false;
        });
    };

    const setEditData = async (data) => {
      consultantAgreementQuery.value.railroadName = data?.railroadCompanyName;
      consultantAgreementQuery.value.consultantCompany =
        data?.consultantCompaniesDTO?.companyName;
      await autoComplete(
        "railroadName",
        consultantAgreementQuery.value.railroadName
      );
      await autoComplete(
        "consultantCompany",
        consultantAgreementQuery.value.consultantCompany
      );
      consultantAgreementForm.value.railroadName =
        data?.railroadCompanyId || data?.railroadCompanyName;
      consultantAgreementForm.value.consultantCompany =
        data?.consultantCompaniesDTO?.orgID;
      await valueSelected("railroadName");

      await valueSelected("consultantCompany");

      consultantAgreementForm.value.consultant =
        data?.consultantCompaniesUsersDTO || [];

      consultantAgreementForm.value.projectScope = data?.scopeOfProject;
      consultantAgreementForm.value.selectedProjects = data?.selectedProjects;
      consultantAgreementForm.value.endDate = data?.endDate
        ? new Date(data.endDate).toLocaleDateString("fr-ca")
        : null;
      consultantAgreementForm.value.isActive = data?.isActive;
      originalConsultantAgreementForm.value = {
        ...JSON.parse(JSON.stringify(consultantAgreementForm.value)),
      };
      isInitialised.value = true;
      loading.value = false;
    };
    watch(
      () => [consultantAgreementForm],
      () => {
        if (
          isInitialised.value &&
          !objectsAreSame(
            originalConsultantAgreementForm.value,
            consultantAgreementForm.value
          )
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    const getDate = (date) => {
      if (date && typeof date === "object") {
        return getFormattedDateStringNoTime(date);
      } else if (date?.includes("-")) {
        return getFormattedDateStringNoTime(date);
      } else {
        return date;
      }
    };
    const { getFormattedDateStringNoTime } = useDateField();
    const arraysEqual = (a, b) => {
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
      for (let i = 0; i < a.length; ++i) {
        if (!b.includes(a[i])) return false;
      }
      return true;
    };
    const arrayOfObjectsEqual = (a, b) => {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a?.length !== b?.length) return false;

      for (let i = 0; i < a.length; ++i) {
        if (!objectsAreSame(a[i], b[i])) return false;
      }
      return true;
    };
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      for (let propertyName in x) {
        if (propertyName === "selectedProjects") {
          if (!arraysEqual(x[propertyName], y[propertyName])) {
            objectsAreSame = false;
            break;
          }
        } else if (propertyName === "consultant") {
          if (!arrayOfObjectsEqual(x[propertyName], y[propertyName])) {
            objectsAreSame = false;
            break;
          }
        } else if (x[propertyName] !== y[propertyName]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        next();
      }
    });
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    const cancelSave = () => {
      isInitialised.value = false;
      valueChanged.value = false;
      /* router.push({
        path: "/GCMS/consultant-agreement",
      }); */
      router.back();
    };

    const filesList = ref([]);
    const otherButtonClicked = () => {
      openDialog(false);
    };
    const updateButtonClicked = (event) => {
      openDialog(true, event);
      uploadConfigs.value = {
        uploadUpdateConfig: {
          isDynamicDocumentType: false,
          staticDocumentTypeList: "Consultant Agreement"
            ? ["Consultant Agreement"]
            : [],

          staticDocumentTypeValue: "Consultant Agreement"
            ? "Consultant Agreement"
            : "",

          isRailroadDropdownStatic: true,
          staticRailroadDropdownList: event.railRoadName
            ? [event.railRoadName]
            : [],

          staticRailroadDropdownValue: event.railRoadName
            ? event.railRoadName
            : "",
          staticDOTDropdownValue: event.dotNumber ? event.dotNumber : "",
          previewDocTypeUrl: "'CONSULTANT_AGREEMENT_DOCUMENTS_DOCTYPE_LIST'",
          mpmsId: id.value,
          isRailroadConsultantAgreementId: true,
          maximumFilesUploadRange: 1,
          showDocTypeText: true,
          showRailroadTypeText: true,
          checkMaximumFilesUploaded: true,
          checkMaxFileSize: true,
        },
        title: "Update Consultant Agreement Document",
        id: id.value,
        isUpdate: true,
        updateEvent: event,
        uploadUpdateApiEvent: { docType: "Consultant Agreement" },
      };
    };
    const formData = ref([]);
    const updateDocumentList = ref(false);
    const openDialog = (isUpdate, event) => {
      formData.value = [];
      let uploadUpdateConfig = isUpdate
        ? {
            isDynamicDocumentType: false,
            staticDocumentTypeList: "Consultant Agreement"
              ? ["Consultant Agreement"]
              : [],

            staticDocumentTypeValue: "Consultant Agreement"
              ? "Consultant Agreement"
              : "",

            isRailroadDropdownStatic: true,
            staticRailroadDropdownList: event.railRoadName
              ? [event.railRoadName]
              : [],

            staticRailroadDropdownValue: event.railRoadName
              ? event.railRoadName
              : "",
            staticDOTDropdownValue: event.dotNumber ? event.dotNumber : "",
            previewDocTypeUrl: "'CONSULTANT_AGREEMENT_DOCUMENTS_DOCTYPE_LIST'",
            mpmsId: id.value,
            isRailroadConsultantAgreementId: true,
            maximumFilesUploadRange: 1,
            showDocTypeText: true,
            showRailroadTypeText: true,
            checkMaximumFilesUploaded: true,
            checkMaxFileSize: true,
          }
        : {
            isDynamicDocumentType: false,
            staticDocumentTypeValue: "Consultant Agreement",
            previewDocTypeUrl: "CONSULTANT_AGREEMENT_DOCUMENTS_DOCTYPE_LIST",
            mpmsId: id.value,
            isRailroadConsultantAgreementId: true,
          };
      uploadUpdateDocumentsRef.value
        .open(
          isUpdate
            ? "Update Consultant Agreement Document"
            : "View/Upload Consultant Agreement Documents",
          id.value,
          isUpdate,
          event,
          uploadUpdateConfig,
          { docType: "Consultant Agreement" }
        )
        .then((resolve) => {
          if (resolve) {
            // refresh data of required
            fetchEstimateData();
          }
        });
    };
    let navigateTo = ref("");
    const filesUploaded = (event) => {
      loading.value = false;
      isInitialised.value = false;
      valueChanged.value = false;
      isDateValidated.value = false;
      uploadConfigs.value = {
        ...uploadConfigs.value,
        uploadUpdateConfig: {
          ...uploadConfigs.value.uploadUpdateConfig,
          saveClicked: false,
        },
      };
      if (navigateTo.value === "add") {
        router.push({
          path: "/GCMS/consultant-agreement/add/" + id.value,
        });
        fetchEstimateData();
        fetchConsultantAgreementData();
      } else if (navigateTo.value === "edit") {
        router.push({
          path: "/GCMS/consultant-agreement/edit/" + id.value,
        });
        fetchEstimateData();
        fetchConsultantAgreementData();
      } else {
        router.back();
        /*  router.push({
          path: "/GCMS/consultant-agreement",
        }); */
      }
    };
    async function fetchEstimateData() {
      updateDocumentList.value = true;
    }
    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };
    const autoComplete = async (field, value = "") => {
      consultantAgreementQuery.value[field] = value;
      if (
        (field === "railroadName" || field === "consultantCompany") &&
        consultantAgreementQuery.value?.[field]
      ) {
        await fetchLookup(field, consultantAgreementQuery.value?.[field]);
      } else if (
        (field === "consultantCompany" &&
          !consultantAgreementForm.value?.["consultantCompany"]) ||
        (field === "railroadName" &&
          !consultantAgreementForm.value?.["railroadName"])
      ) {
        consultantAgreementQuery.value[field] = "";
        consultantAgreementLookup.value[field] = [];
      }
    };
    const valueSelected = async (field) => {
      if (field === "railroadName") {
        let selectedRailroadCompany = consultantAgreementLookup.value[
          field
        ]?.filter((consultant) => {
          return (
            consultant.railroadCompanyId ===
              consultantAgreementForm.value?.[field] ||
            consultant.name === consultantAgreementForm.value?.["railroadName"]
          );
        })?.[0];
        if (selectedRailroadCompany) {
          await axios
            .post(CONFIG_ENDPOINT.CONSULATNT_AGREEMENT_SELECTED_PROJECT_SOP, {
              selectedRailroadCompanyName: consultantAgreementForm.value[field],
              railroadCompanyId:
                consultantAgreementLookup.value[field]?.[0]?.railroadCompanyId,
              selectedConsultantCompanyName: null,
            })
            .then((res) => {
              consultantAgreementForm.value["selectedProjects"] = [];
              consultantAgreementForm.value["projectScope"] = "";
              consultantAgreementLookup.value["selectedProjects"] =
                res.data.data.selecttedProjects || [];
              consultantAgreementLookup.value["projectScope"] =
                res.data.data.scopeOfProject || [];
            })
            .catch((err) => {
              consultantAgreementForm.value["selectedProjects"] = [];
              consultantAgreementForm.value["projectScope"] = "";
              consultantAgreementLookup.value["selectedProjects"] = [];
              consultantAgreementLookup.value["projectScope"] = [];
            });
        }
      } else if (field === "consultantCompany") {
        let selectedConsultantCompany = consultantAgreementLookup.value[
          field
        ]?.filter((consultant) => {
          return consultant.orgID === consultantAgreementForm.value?.[field];
        })?.[0];
        if (selectedConsultantCompany) {
          await axios
            .post(
              CONFIG_ENDPOINT.CONSULATNT_AGREEMENT_CONSULTANT_lOOKUP,
              selectedConsultantCompany
            )
            .then((res) => {
              consultantAgreementForm.value["consultant"] =
                res.data.data?.length === 1 ? res.data.data : [];
              consultantAgreementLookup.value["consultant"] =
                res.data.data || [];
            })
            .catch((err) => {
              consultantAgreementForm.value["consultant"] = [];
              consultantAgreementLookup.value["consultant"] = [];
            });
        }
      } else if (field === "projectScope") {
        consultantAgreementForm.value["selectedProjects"] = [];
      }
    };
    const fetchLookup = async (field, searchText) => {
      if (field === "railroadName") {
        await axios
          .post(CONFIG_ENDPOINT.CONSULATNT_AGREEMENT_RAILROAD_LIST_LOOKUP, {
            railroadName: searchText,
          })
          .then((res) => {
            consultantAgreementLookup.value[field] = res.data || [];
          })
          .catch((err) => {
            consultantAgreementLookup.value[field] = [];
          });
      } else if (field === "consultantCompany") {
        if (searchText) {
          await axios
            .get(
              CONFIG_ENDPOINT.CONSULATNT_AGREEMENT_CONSULTANT_COMPANY_lOOKUP +
                searchText
            )
            .then((res) => {
              consultantAgreementLookup.value[field] = res.data.data || [];
            })
            .catch((err) => {
              consultantAgreementLookup.value[field] = [];
            });
        }
      }
    };
    const savedFilesLength = ref(0);
    const uploadedFilesLength = ref(0);
    const filesLength = (length) => {
      savedFilesLength.value = length;
    };
    const uploadedFilesLengthEvent = (length) => {
      uploadedFilesLength.value = length;
    };
    const getPayload = () => {
      let selectedConsultantCompany = consultantAgreementLookup.value[
        "consultantCompany"
      ]?.filter((consultant) => {
        return (
          consultant.orgID ===
          consultantAgreementForm.value?.["consultantCompany"]
        );
      })?.[0];
      let selectedConsultantConsultants = [];
      if (consultantAgreementForm.value?.["consultant"]?.length) {
        if (consultantAgreementForm.value?.["consultant"]?.[0]?.userID) {
          let userIdArray = consultantAgreementForm.value?.["consultant"]?.map(
            (consultant) => consultant?.userID
          );
          selectedConsultantConsultants = consultantAgreementLookup.value[
            "consultant"
          ]?.filter((consultant) => {
            return userIdArray.includes(consultant.userID);
          });
        } else {
          selectedConsultantConsultants = consultantAgreementLookup.value[
            "consultant"
          ]?.filter((consultant) => {
            return consultantAgreementForm.value?.["consultant"]?.includes(
              consultant.userID
            );
          });
        }
      }
      let selectedRailroadCompany = consultantAgreementLookup.value[
        "railroadName"
      ]?.filter((consultant) => {
        return (
          consultant.railroadCompanyId ===
            consultantAgreementForm.value?.["railroadName"] ||
          consultant.name === consultantAgreementForm.value?.["railroadName"]
        );
      })?.[0];
      const payload = {
        railroadConsultantAgreementId: isAdd ? null : id.value,
        railroadCompanyName: selectedRailroadCompany?.name,
        railroadCompanyId: selectedRailroadCompany?.railroadCompanyId,
        scopeOfProject: consultantAgreementForm.value?.projectScope,
        selectedProjects: consultantAgreementForm.value?.selectedProjects?.map(
          (project) => "" + project
        ),
        endDate: getDate(consultantAgreementForm.value?.endDate),
        isActive: consultantAgreementForm.value?.isActive,
        consultantCompaniesDTO: selectedConsultantCompany,
        consultantCompaniesUsersDTO: selectedConsultantConsultants,
      };
      return payload;
    };
    const bannerDetails = ref({
      showBanner: false,
      isSuccess: false,
      message: "",
    });

    const hideBanner = () => {
      setBannerDetails(false, false, "");
    };
    const setBannerDetails = (showBanner, isSuccess, message) => {
      bannerDetails.value = {
        showBanner: showBanner,
        isSuccess: isSuccess,
        message: message,
      };
      if (showBanner) {
        window.scrollTo(0, 0);
      }
    };
    const saveConsultantAgreement = async () => {
      loading.value = true;
      const payload = getPayload();
      saveAndApproveAPICall(payload, false);
    };
    const saveAndApproveAPICall = async (payload, isApprove) => {
      await axios
        .post(CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_ADD, {
          ...payload,
          status: isApprove ? "Approved" : "",
        })
        .then((res) => {
          setBannerDetails(true, true, res?.data?.data?.businessMessage);

          if (!id.value && res?.data?.data?.railroadConsultantAgreementId) {
            navigateTo.value = "add";
            if (res?.data?.data?.railroadConsultantAgreementId) {
              id.value = res?.data?.data?.railroadConsultantAgreementId;
            }
          } else if (isAdd && id.value) {
            navigateTo.value = "edit";
            if (res?.data?.data?.railroadConsultantAgreementId) {
              id.value = res?.data?.data?.railroadConsultantAgreementId;
            }
          }
          uploadConfigs.value = {
            ...uploadConfigs.value,
            uploadUpdateConfig: {
              ...uploadConfigs.value.uploadUpdateConfig,
              saveClicked: true,
            },
          };
          loading.value = false;
        })
        .catch((err) => {
          loading.value = false;
        });
    };
    const submitConsultantAgreement = async () => {
      if (uploadedFilesLength.value > 0 || savedFilesLength.value > 0) {
        setBannerDetails(false, false, "");
        loading.value = true;
        const payload = getPayload();
        buttonClicked.value = "submit";
        submitAPICall(payload);
      } else {
        setBannerDetails(
          true,
          false,
          "At least one document should be entered."
        );
      }
    };
    const submitAPICall = async (payload) => {
      await axios
        .post(CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_SUBMIT, {
          ...payload,
          status: null,
        })
        .then((res) => {
          uploadConfigs.value = {
            ...uploadConfigs.value,
            uploadUpdateConfig: {
              ...uploadConfigs.value.uploadUpdateConfig,
              saveClicked: true,
            },
          };
          loading.value = false;
          setBannerDetails(true, true, res?.data?.data?.businessMessage);
          buttonClicked.value = "";
        })
        .catch((err) => {
          loading.value = false;
          buttonClicked.value = "";
        });
    };
    const removeConsultant = (index) => {
      consultantAgreementForm.value.consultant?.splice(index, 1);
    };
    const getConsultantName = (id) => {
      return consultantAgreementLookup.value.consultant?.filter(
        (consultant) => consultant.userID === id
      )?.[0]?.fullName;
    };
    let isSaveDisabled = ref();
    const returnFormMoreInfo = () => {
      buttonClicked.value = "return";
      returnReasonModalRef.value
        .open("Return Consultant Agreement Form", ["hi"], null, false)
        .then(async (resolve) => {
          if (resolve) {
            const payload = getPayload();
            await axios
              .post(CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_RETURN_REASON, {
                railroadConsultantAgreementId:
                  payload?.railroadConsultantAgreementId,
                railroadCompanyName: payload?.railroadCompanyName,
                returnReason: returnValue.value,
                formName: "Consultant Agreement",
                submitUser: consultantDetails.value.submitUser,
              })
              .then((res) => {
                setBannerDetails(true, true, res?.data?.data);
                buttonClicked.value = "";

                router.back();
                /* router.push({
                  path: "/GCMS/consultant-agreement",
                }); */
              })
              .catch((err) => {
                buttonClicked.value = "";
              });
          } else {
            buttonClicked.value = "";
          }
        });
    };

    const emitReason = (event) => {
      returnValue.value = event;
    };

    const isUploadButtonDisabled = (event) => {
      /* isSaveDisabled.value = event;
      if (!event) {
        //isSaveClicked.value = false;
      } */
    };
    const approve = () => {
      loading.value = true;
      const payload = getPayload();
      saveAndApproveAPICall(payload, true);
    };
    return {
      getConsultantName,
      removeConsultant,
      bannerDetails,
      submitConsultantAgreement,
      saveConsultantAgreement,
      valueSelected,
      consultantAgreementForm,
      uploadUpdateDocumentsRef,
      otherButtonClicked,
      updateDocumentList,
      getDate,
      updateDocumentListFlag,
      updateButtonClicked,
      isFormValid,
      consultantDetails,
      id,
      arrayRequiredRule,
      requiredRule,
      onlyFutureDateAccepted,
      dateChange,
      checkDateValidation,
      valueChanged,
      minDate,
      maxDate,
      consultantAgreementQuery,
      autoComplete,
      consultantAgreementLookup,
      isDateValidated,
      uploadedFilesLength,
      filesLength,
      isAdd,
      cancelSave,
      hideBanner,
      userData,
      filesList,
      loading,
      AUTO_COMPLETE_NO_DATA,
      isInitialised,
      isConsultantAgreementAccess,
      clearConsulatantCompany,
      uploadConfigs,
      filesUploaded,
      isUploadButtonDisabled,
      isSaveDisabled,
      isReview,
      returnFormMoreInfo,
      approve,
      emitReason,
      returnReasonModalRef,
      buttonClicked,
      uploadedFilesLengthEvent,
      disableFields,
    };
  },
};
