<template>
    <v-dialog
      persistent
      v-model="dialog"
      :max-width="maxWidth ? maxWidth : '1000' + 'px'"
      max-height="3000px"
    >
      <div class="dialogheading">
        <div class="w-100 d-flex justify-content-between">
          <div class="w-90 text-center f-size24">
            {{ heading }}
          </div>
          <div>
            <v-icon @click="handleNo" class="cursor-pointer" small
              >mdi-close
            </v-icon>
          </div>
        </div>
        <v-card>
          <v-card-text>
            <v-row>
                <v-col cols="3">
              <label>Reason for Return: </label> <label class="asterisk">*</label>
            </v-col>
            <v-col cols="8">
                <v-textarea
                      :counter="500"
                      :maxlength="500"
                      :persistent-counter="true"
                      v-model="reason"
                      :spellcheck="true"
                      variant="solo"
                    ></v-textarea>
            </v-col>
            </v-row>
            <v-row> 
              <v-col cols="3">
                </v-col>
                  <span
            >     {{ 500 - (reason?.length || 0) }} characters
            remaining.</span>
            </v-row>
            <br>
          </v-card-text>
          <v-card-actions>
            <div class="action">
              <PrimaryButton
                @buttonClicked="handleYes"
                :disabled="!reason"
                :buttonText="'Return'"
              ></PrimaryButton>
              <SecondaryButton
                v-if="!hideCancelButton"
                @buttonClicked="handleNo"
                :buttonText="'Cancel'"
                class="ml-2"
              ></SecondaryButton>
            </div>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </template>
  <script>
  import ReturnReasonModal from "@/modules/shared/scripts/ReturnReasonModal.js";
  export default ReturnReasonModal;
  </script>
  <style>
  @import "@/assets/css/style.scss";
  </style>
  