<template>
  <div>
    <v-form v-model="isFormValid" class="pa1Form priorityForm">
      <v-grid>
        <v-row justify="center">
          <v-col cols="11">
            <h2
              class="f-size18 f-montserrat text-left mt-50"
              style="font-size: 18px; padding-bottom: 0.5em; color: #003e7e"
            >
              Upload Consultant Agreement Documents
            </h2>
            <p
              class="text-left"
              style="padding-bottom: 1px; padding-left: 10px"
            >
              Maximum allowable file size is 500 MB.
            </p>
            <UploadScopingFieldViewDocument
              @uploadFilesCall="uploadFiles"
              @valueChangedEvent="valueChangedEvent($event)"
              @isUploadButtonDisabled="$emit('isUploadButtonDisabled', $event)"
              @uploadedFiles="uploadedFilesEvent"
              :uploadedDocumentsLength="
                uploadUpdateConfig?.uploadedDocumentsLength
              "
              :mpmsId="id"
              :saveClicked="isSaveButtonClicked"
              :checkMaxFileSize="true"
              :checkMaximumFilesUploaded="true"
              :maximumFilesUploadRange="5"
              :showPreviewRailroadColumn="
                uploadUpdateConfig?.showPreviewRailroadColumn
              "
              :showPreviewDOTColumn="uploadUpdateConfig?.showPreviewDOTColumn"
              :isDynamicDocumentType="uploadUpdateConfig?.isDynamicDocumentType"
              :staticDocumentTypeList="
                uploadUpdateConfig?.staticDocumentTypeList
              "
              :staticDocumentTypeValue="
                uploadUpdateConfig?.staticDocumentTypeValue
              "
              :isRailroadDropdownStatic="
                uploadUpdateConfig?.isRailroadDropdownStatic
              "
              :staticRailroadDropdownList="
                uploadUpdateConfig?.staticRailroadDropdownList
              "
              :staticRailroadDropdownValue="
                uploadUpdateConfig?.staticRailroadDropdownValue
              "
              :staticDOTDropdownList="uploadUpdateConfig?.staticDOTDropdownList"
              :staticDOTDropdownValue="
                uploadUpdateConfig?.staticDOTDropdownValue
              "
              :previewDocTypeUrl="uploadUpdateConfig?.previewDocTypeUrl"
              :showDocTypeText="uploadUpdateConfig?.showDocTypeText"
              :showRailroadTypeText="uploadUpdateConfig?.showRailroadTypeText"
              :isRailroadDropdownDisabled="
                uploadUpdateConfig?.isRailroadDropdownDisabled
              "
              :isRailroadWorkSummary="uploadUpdateConfig?.isRailroadWorkSummary"
              :isRailroadWorkSummaryDOTList="
                uploadUpdateConfig?.isRailroadWorkSummaryDOTList
              "
              :isPageUpload="uploadUpdateConfig?.isPageUpload"
              :isContractorBidSafetyMilestone="
                uploadUpdateConfig?.isContractorBidSafetyMilestone
              "
              :hideUploadButton="true"
            />
          </v-col>
        </v-row>
      </v-grid>
    </v-form>
  </div>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
  <script>
import UploadUpdateRailroadDotDocumentSamePage from "@/modules/shared/scripts/UploadUpdateRailroadDotDocumentSamePage.js";
export default UploadUpdateRailroadDotDocumentSamePage;
</script>
<style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>
