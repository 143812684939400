import ConsultantAgreement from "@/modules/gcmsmain/admin/submenu/components/ConsultantAgreement.vue";import ConsultantCompanyInfo from "@/modules/gcmsmain/admin/submenu/components/ConsultantCompanyInfo.vue";
import ConsultingCompanyUsers from "@/modules/gcmsmain/admin/submenu/components/ConsultingCompanyUsers.vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "ConsultingCompany",
  components: {
    ConsultantCompanyInfo,
    ConsultingCompanyUsers,
    ConsultantAgreement,
  },
  setup() {
    const route = useRoute();
    const companyName = route.params.id;
    const tab = ref("companyInformation");
    onMounted(async () => {
      if (localStorage.getItem("consultantTab")) {
        tab.value = localStorage.getItem("consultantTab");
      }
    });
    const onTabChange = (tab) => {
      if (tab === "ConsultantAgreements") {
        localStorage.setItem("consultantTab", tab);
      } else {
        localStorage.removeItem("consultantTab");
      }
    };
    return {
      route,
      companyName,
      tab,
      onTabChange,
    };
  },
};
