import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";import { computed, onBeforeUnmount, onMounted, ref } from "vue";
export default {
  name: "DropZone",
  components: { PrimaryButton },
  emits: ["UpdateFiles"],
  props: {
    uploadedDocumentsLength: { type: Number, default: 0 },
    files: { type: Array, required: true },
    checkMaxFileSize: { type: Boolean, default: false },
    checkMaximumFilesUploaded: { type: Boolean, default: false },
    maximumFilesUploadRange: { type: Number, default: 5 },
  },
  setup(props, { emit }) {
    const acceptableFileFormats = ref([
      ".pdf",
      ".csv",
      ".xlsx",
      ".png",
      ".jpg",
      ".PNG",
      ".JPG",
    ]);
    const dragging = ref(false);
    const draggingOverContainer = ref(false);
    const selectedFiles = ref([]);
    const invalidFiles = ref(false);
    const incorrectFiles = ref([]);
    const uploadedFile = ref();
    const showMaxFileError = ref(false);
    let maximumFilesUploaded = ref(false);

    const checkMaximumFilesUploaded = computed({
      get: () => {
        return props?.checkMaximumFilesUploaded;
      },
    });
    const checkMaxFileSize = computed({
      get: () => {
        return props?.checkMaxFileSize;
      },
    });
    const handleDrop = (event) => {
      event.preventDefault();
      if (draggingOverContainer.value) {
        dragging.value = false;
        const droppedFiles = event.dataTransfer.files;
        handleFiles(droppedFiles);
        draggingOverContainer.value = false;
      }
    };

    const handleFiles = (fileList) => {
      const acceptableFiles = [];
      let selectedFiles = Array.from(fileList);
      invalidFiles.value = false;
      incorrectFiles.value = [];
      selectedFiles.forEach((file) => {
        let fileFormat =
          "." + file.name.split(".")[file.name.split(".").length - 1];
        if (acceptableFileFormats.value.includes(fileFormat)) {
          acceptableFiles.push(file);
        } else {
          invalidFiles.value = true;
          incorrectFiles.value.push({ name: file.name, size: file.fileSize });
        }
      });
      selectedFiles.value = acceptableFiles;
      if (props?.checkMaximumFilesUploaded) {
        let uploadedFilesLength = props?.files?.length;
        if (props?.maximumFilesUploadRange - uploadedFilesLength > 0) {
          selectedFiles.value = acceptableFiles.slice(
            0,
            props?.maximumFilesUploadRange - uploadedFilesLength
          );
          maximumFilesUploaded.value = false;
        } else {
          selectedFiles.value = [];
          maximumFilesUploaded.value = true;
        }
      }
      if (props?.checkMaxFileSize) {
        let exceedSizLimitFiles = selectedFiles.filter(
          (file) => file.size / 1024 / 1024 > 500
        );
        if (exceedSizLimitFiles.length) {
          showMaxFileError.value = true;
        } else {
          showMaxFileError.value = false;
        }
      }
      emit("UpdateFiles", selectedFiles.value);
      uploadedFile.value = null;
    };

    const openFileInput = () => {
      const fileInput = document.getElementById("fileInput");
      fileInput.click();
    };

    const handleFileInput = (event) => {
      const fileList = event;
      console.log("fileList----", event);
      handleFiles(fileList);
    };
    const handleDragOver = (event) => {
      event.preventDefault();
      dragging.value = true;
    };

    const handleDragLeave = () => {
      dragging.value = false;
    };

    onMounted(() => {
      document.addEventListener("dragover", handleDragOver);
      document.addEventListener("dragleave", handleDragLeave);
      document.addEventListener("drop", handleDrop);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("drop", handleDrop);
    });

    const removeError = () => {
      invalidFiles.value = false;
      incorrectFiles.value = [];
    };
    const removeMaxError = () => {
      maximumFilesUploaded.value = false;
    };
    const removeMaxFileSizeError = () => {
      showMaxFileError.value = false;
    };

    return {
      draggingOverContainer,
      dragging,
      selectedFiles,
      openFileInput,
      handleFileInput,
      handleDrop,
      invalidFiles,
      incorrectFiles,
      removeError,
      maximumFilesUploaded,
      removeMaxError,
      uploadedFile,
      removeMaxFileSizeError,
      showMaxFileError,
      checkMaximumFilesUploaded,
      checkMaxFileSize,
    };
  },
};
