import PartIIITrafficControl from "@/modules/dot-details/components/PartIIITrafficControl.vue";import PartIIRailRoadInfo from "@/modules/dot-details/components/PartIIRailRoadInfo.vue";
import PartILocClassification from "@/modules/dot-details/components/PartILocClassification.vue";
import PartIVPhysicalChar from "@/modules/dot-details/components/PartIVPhysicalChar.vue";
import PartVPublicHwyInfo from "@/modules/dot-details/components/PartVPublicHwyInfo.vue";
import PennDotCrossingInfo from "@/modules/dot-details/components/PennDotCrossingInfo.vue";
import { computed, ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";

export default {
  name: "CrossingDetailsTabView",
  components: {
    PartILocClassification,
    PartIIRailRoadInfo,
    PartIIITrafficControl,
    PartIVPhysicalChar,
    PartVPublicHwyInfo,
    PennDotCrossingInfo,
  },
  props: ["dotId", "tab"],
  emits: ["showBanner", "updateSubTabValue"],
  setup(props, context) {
    let panel = ref([0, 1]);
    const tab = ref("part1");
    const currentTab = ref("part1");
    const dotId = computed({
      get: () => {
        return props?.dotId;
      },
    });
    const getCurrentTab = computed({
      get: () => {
        return currentTab?.value;
      },
    });
    const onTabChange = (eventTab) => {
      if (isValueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          isValueChanged.value = false;
          currentTab.value = eventTab;
          context.emit("updateSubTabValue", currentTab.value);
        } else {
          currentTab.value = tab.value;
          context.emit("updateSubTabValue", currentTab.value);
        }
      } else {
        tab.value = eventTab;
        currentTab.value = eventTab;

        context.emit("updateSubTabValue", currentTab.value);
      }
    };
    const isValueChanged = ref(false);
    const showBannerMessage = (event) => {
      context.emit("showBanner", event);
    };
    const valueChanged = (event) => {
      isValueChanged.value = event;
    };
    window.onbeforeunload = function () {
      if (isValueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    onBeforeRouteLeave((to, from, next) => {
      if (isValueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          isValueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        isValueChanged.value = false;
        next();
      }
    });

    return {
      tab,
      dotId,
      panel,
      showBannerMessage,
      onTabChange,
      currentTab,
      getCurrentTab,
      valueChanged,
      isValueChanged,
    };
  },
};
