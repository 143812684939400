<script>
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import router from "@/router";
import { useUserStore } from "@/stores/userStore";
import axios from "axios";
import { ref } from "vue";

export default {
  name: "CallbackPage",
  setup() {
    const originalUrl = localStorage.getItem("originalUrl");
    let url = "";
    const userStore = useUserStore();
    const railroadRole = ref(false);
    const consultantRole = ref(false);
    const railRoadList = ref([]);
    const textFilter = ref([]);
    const multipleFilter = ref([]);

    const routePage = () => {
      if (originalUrl) {
        try {
          url = new URL(originalUrl);
          if (
            url.pathname.indexOf("callback") >= 0 ||
            url.pathname == "/" ||
            url.pathname == "/GCMS/" ||
            url.pathname == "/GCMS"
          ) {
            router.push({
              path: "/dashboard",
            });
          } else {
            router.push({
              path: url.pathname,
            });
          }
        } catch (e) {
          router.push({
            path: "/dashboard",
          });
        }
        localStorage.removeItem("originalUrl");
      } else {
        router.push({
          path: "/dashboard",
        });
      }
    };

    const firstLoginRoute = async (tempId) => {
      let Filter = {
        name: null,
        isActive: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value?.length) {
          Filter[filter.key] = filter.value;
        }
      });
      let filterText = "";
      const response = await axios.post(
        CONFIG_ENDPOINT.GET_RAILROAD_LIST + filterText,
        Filter
      );
      railRoadList.value = response.data.railRoadAdminDTO;
      userStore.setIsLinkedUserWarning(true);
      router.push({
        path:
          "GCMS/manage-railroad-companies/edit/" +
          tempId +
          "/" +
          railRoadList.value[0]?.name,
      });
    };

    const checkLinkedUser = async () => {
      axios
        .get(CONFIG_ENDPOINT.IS_RAILROAD_USER_CONSULTANT)
        .then((response) => {
          railroadRole.value = response.data.railroadUser;
          userStore.setRailroadUser(railroadRole.value);
          consultantRole.value = response.data.railRoadConsultant;
          if (railroadRole.value || consultantRole.value) {
            axios
              .get(CONFIG_ENDPOINT.LINK_REGISTERED_USERS)
              .then(async (response) => {
                let tempId = response.data.data.railroadCompanyId;
                const businessMessage = response.data.data.businessMessage;
                const primaryMessage =
                  "GCMS cannot link your User ID and Organization with a Railroad record. Please Contact the GCMS Help Desk (link=ra-pdgcms_help@pa.gov)";
                const secondaryMessage =
                  "GCMS cannot match you to an existing Railroad. Please Contact the GCMS Help Desk";
                if (businessMessage === primaryMessage) {
                  userStore.setMatchedRailroadErrorMessage(businessMessage);
                  userStore.setMatchedRailroadErrorBanner(true);
                  routePage();
                } else if (businessMessage === secondaryMessage) {
                  routePage();
                } else if (response.data.data.firstLogin) {
                  firstLoginRoute(tempId);
                } else {
                  routePage();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            routePage();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    checkLinkedUser();

    return {
      routePage,
      checkLinkedUser,
      railRoadList,
      textFilter,
      multipleFilter,
      firstLoginRoute,
    };
  },
};
</script>