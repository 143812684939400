<template>
  <v-card-text>
    <ProjectFollowers
      @BannerUpdate="$emit('BannerUpdate', $event)"
      :refreshOnFollowButtonClick="refreshOnFollowButtonClick"
      @followPageRefreshed="$emit('followPageRefreshed', $event)"
    ></ProjectFollowers>
  </v-card-text>
</template>
    <script>
import ProjectFollowers from "@/modules/manageproject/components/ProjectFollowers.vue";
import { computed } from "vue";

export default {
  name: "ViewProjectFollowers-page",
  components: {
    ProjectFollowers: ProjectFollowers,
  },
  props: ["refreshOnFollowButtonClick"],
  setup(props) {
    const refreshOnFollowButtonClick = computed(
      () => props?.refreshOnFollowButtonClick
    );
    return { refreshOnFollowButtonClick };
  },
};
</script>
    