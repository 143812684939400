import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";
export const useUserStore = defineStore("user", () => {
  let loggedInUser = ref(null);
  let isRailroadUser = ref(null);
  let isAuthenticated = ref(false);
  let isLinkedUserWarning = ref(false);
  let matchedRailroadErrorBanner = ref(false);
  let matchedRailroadErrorMessage = ref("");

  const setUser = (user) => {
    loggedInUser.value = user;
  };
  const setIsAuthenticated = (value) => {
    isAuthenticated.value = value;
  };

  const setIsLinkedUserWarning = (value) => {
    isLinkedUserWarning.value = value;
  };

  const setMatchedRailroadErrorBanner = (value) => {
    matchedRailroadErrorBanner.value = value;
  };

  const setMatchedRailroadErrorMessage = (value) => {
    console.log("in user store");
    console.log(value);
    matchedRailroadErrorMessage.value = value;
  };

  const getTimeOutData = async () => {
    let url = CONFIG_ENDPOINT.GCMS_MANAGEUSER_TIMEOUT;
    return await axios
      .get(url)
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };
  const setRailroadUser = (user) => {
    isRailroadUser.value = user;
  };
  const getRailroadUser = () => {
    return isRailroadUser.value;
  };
  return {
    loggedInUser,
    isAuthenticated,
    setUser,
    setIsAuthenticated,
    getTimeOutData,
    setIsLinkedUserWarning,
    isLinkedUserWarning,
    matchedRailroadErrorBanner,
    matchedRailroadErrorMessage,
    setMatchedRailroadErrorBanner,
    setMatchedRailroadErrorMessage,
    setRailroadUser,
    getRailroadUser,
  };
});
