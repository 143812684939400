import useSharedFunctions from "@/composables/shared";import ConsultantAgreement from "@/modules/gcmsmain/admin/submenu/components/ConsultantAgreement.vue";
import ContactInfo from "@/modules/gcmsmain/admin/submenu/components/ContactList.vue";
import RailroadCompanyInformationForm from "@/modules/gcmsmain/admin/submenu/components/RailroadCompanyInformationForm.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import { useUserStore } from "@/stores/userStore";
import { onMounted, ref } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";

export default {
  name: "AddUpdateRailroadCompany",
  components: {
    RailroadCompanyInformationForm,
    BannerMessage,
    ContactInfo,
    OnlineHelpPOC,
    ConsultantAgreement,
  },
  setup() {
    const { toTitleCase } = useSharedFunctions();
    const route = useRoute();
    const addOrEdit = route.params.action;
    const railroadId = route.params.id;
    const tab = ref("RailroadInformation");
    const SuccessBannerData = ref(null);
    const ErrorBannerData = ref(null);
    const savedButtonClicked = ref(false);
    const userStore = useUserStore();
    const isLinkedUserWarning = ref(false);

    onMounted(async () => {
      if (localStorage.getItem("railroadConsultantTab")) {
        tab.value = localStorage.getItem("railroadConsultantTab");
      }
      if (userStore.isLinkedUserWarning) {
        isLinkedUserWarning.value = true;
      }
    });

    const BannerUpdate = (event) => {
      if (event?.bannerType === "success") {
        SuccessBannerData.value = event;
        hideErrorBannerMessageLinkedUser();
      } else if (event?.bannerType === "error") {
        ErrorBannerData.value = event;
      }
    };
    const hideSuccessBanner = () => {
      SuccessBannerData.value = null;
    };

    const hideErrorBannerMessage = () => {
      ErrorBannerData.value = null;
    };
    const hideErrorBannerMessageLinkedUser = () => {
      isLinkedUserWarning.value = false;
      userStore.setIsLinkedUserWarning(false);
    };

    const saveClicked = (clicked) => {
      savedButtonClicked.value = clicked;
      hideErrorBannerMessageLinkedUser();
    };

    onBeforeRouteLeave((to, from, next) => {
      hideErrorBannerMessageLinkedUser();
      next();
    });
    window.onbeforeunload = function () {
      hideErrorBannerMessageLinkedUser();
      return;
    };
    const onTabChange = (tab) => {
      if (tab === "ConsultantAgreements") {
        localStorage.setItem("railroadConsultantTab", tab);
      } else {
        localStorage.removeItem("railroadConsultantTab");
      }
    };

    return {
      addOrEdit,
      railroadId,
      tab,
      toTitleCase,
      BannerUpdate,
      SuccessBannerData,
      hideSuccessBanner,
      hideErrorBannerMessage,
      ErrorBannerData,
      saveClicked,
      savedButtonClicked,
      ContactInfo,
      userStore,
      isLinkedUserWarning,
      hideErrorBannerMessageLinkedUser,
      onTabChange,
    };
  },
};
