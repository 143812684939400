import useSharedFunctions from "@/composables/shared";import {
  greaterThanEqualToZero,
  maxLengthRule1,
  maxLengthRule2,
  maxLengthRule999,
  minLengthRule1,
  minimumRule,
  numericRule,
  phoneRule,
  requiredRule,
  validateEmail,
  zipRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import AddressInput from "@/modules/shared/components/AddressInput.vue";
import EmailInput from "@/modules/shared/components/EmailInput.vue";
import PhoneInput from "@/modules/shared/components/PhoneInput.vue";

import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";

import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { useProjectServiceStore } from "@/stores/projectService";

import axios from "axios";
import { computed, onMounted, reactive, ref, toRefs, watch } from "vue";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    VueAutoComplete,
    AddressInput,
    EmailInput,
    PhoneInput,
  },
  props: {
    formData: {
      type: Object,
      default: {
        firstName: "",
        middleName: "",
        lastName: "",
        suffix: "",
        primaryPhone: "",
        primaryPhoneExt: "",
        secondaryPhone: "",
        secondaryPhoneExt: "",
        email: "",
        userID: "",
        postUserId: "",
        railroadCompanyId: "",
        railroadContactId: "",
        address1: "",
        address2: "",
        city: "",
        stateCode: "",
        zipcode: "",
      },
    },
    id: { type: String, default: null },
    item: Object, // Item to be edited (if in 'edit' mode)    projectDetails: Object,
    itemIndex: "",
  },
  setup() {
    const {
      checkValidEmailcode,
      checkValidPhoneCode,
      checkAddressInputValidation,
    } = useSharedFunctions();

    let isFormValid = ref(false);
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      dialogType: null,
      formData: {},
      lookupData: null,
      SAPDetails: null,
    });
    const projectServiceStore = useProjectServiceStore();
    const originalValue = ref();
    const isInitialised = ref(false);
    const valueChanged = ref(false);
    const states = ref([]);
    const open = (title, formData, dialogType) => {
      state.dialog = true;
      state.title = title;
      state.formData = formData;
      originalValue.value = JSON.parse(JSON.stringify(formData));
      setTimeout(() => {
        isInitialised.value = true;
      }, 1000);
      state.dialogType = dialogType;
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    onMounted(async () => {
      await fetchStates();
    });
    async function fetchStates() {
      let response = projectServiceStore.stateList;
      if (!response) {
        await projectServiceStore.retrieveStateList();
        response = projectServiceStore.stateList;
      }
      states.value = response?.data;
    }
    const id = ref(null);
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });

    const saveData = () => {
      let object = {
        firstName: state.formData.firstName,
        middleName: state.formData.middleName,
        lastName: state.formData.lastName,
        suffix: state.formData.suffix,
        primaryPhone: state.formData.primaryPhone,
        primaryPhoneExt: state.formData.primaryPhoneExt,
        secondaryPhone: state.formData.secondaryPhone,
        secondaryPhoneExt: state.formData.secondaryPhoneExt,
        email: state.formData.email,
        userID: state.formData.userID,
        postUserId: userData.value.userID,
        railroadCompanyId: state.formData.railroadCompanyId,
        railroadContactId: state.formData.railroadContactId,
        address1: state.formData.address1,
        address2: state.formData.address2,
        city: state.formData.city,
        stateCode: state.formData.stateCode,
        zipcode: state.formData.zipcode,
      };
      if (state.dialogType == "Edit") {
        UpdateContact(object);
      } else {
        AddContact(object);
      }
    };
    const AddContact = (value) => {
      axios
        .post(CONFIG_ENDPOINT.ADD_RAILROAD_CONTACTS, value)
        .then(() => {
          state.resolve({
            type: state.message,
            index: state.itemIndex,
          });

          state.dialog = false;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const UpdateContact = (value) => {
      axios
        .put(CONFIG_ENDPOINT.UPDATE_RAILROAD_CONTACTS, value)
        .then(() => {
          state.resolve({
            type: state.message,
            index: state.itemIndex,
          });

          state.dialog = false;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          state.resolve(false);
          state.dialog = false;
          valueChanged.value = false;
        }
      } else {
        state.resolve(false);
        state.dialog = false;
        valueChanged.value;
      }
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = null;
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    let typingValues = ref({
      email: { stoppedTyping: null },
      primaryPhone: { stoppedTyping: null },
      secondaryPhone: { stoppedTyping: null },
    });

    const fieldValueChange = (field) => {
      typingValues.value[field].stoppedTyping = false;
    };
    const setFieldRule = (field) => {
      typingValues.value[field].stoppedTyping = true;
    };

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 1000);
      };
    }

    function keydownAllowNumberOnly(event) {
      if (isLeftUpRightDownBackspaceDeleteTab(event)) {
        return;
      }

      let regex = /\d/;

      if (!regex.test(event.key)) {
        event.preventDefault();
        return false;
      }
    }
    function isLeftUpRightDownBackspaceDeleteTab(event) {
      let key = event.key || event.code;
      return (
        key === "ArrowLeft" ||
        key === 37 ||
        key === "ArrowUp" ||
        key === 38 ||
        key === "ArrowRight" ||
        key === 39 ||
        key === "ArrowDown" ||
        key === 40 ||
        key === "Backspace" ||
        key === 8 ||
        key === "Delete" ||
        key === 46 ||
        key === "Tab" ||
        key === 9
      );
    }

    const filterObject = (item, queryText, itemText) => {
      return item?.toLowerCase()?.startsWith(queryText?.toLowerCase());
    };

    function allowAlphaOnly(event) {
      const blockSpecialCharacterRegex = /^[A-Z\s]+$/i;
      let key = event.key ? event.key : String.fromCharCode(event.code);
      if (!blockSpecialCharacterRegex.test(key)) {
        event.preventDefault();
        return false;
      }
    }

    function isNumber(event) {
      if (isLeftUpRightDownBackspaceDeleteTab(event)) return;
      const regex = /^[0-9,\s]+$/i;
      let key = event.key ? event.key : String.fromCharCode(event.code);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    }
    const isEmailFormValid = ref(false);
    const isPhonesFormValid = ref(false);
    const isAddressFormValid = ref(false);
    watch(
      () => [state.formData],
      () => {
        if (checkAddressInputValidation(state.formData)) {
          isAddressFormValid.value = true;
        } else {
          isAddressFormValid.value = false;
        }
        if (checkValidEmailcode(state.formData)) {
          isEmailFormValid.value = true;
        } else {
          isEmailFormValid.value = false;
        }

        let primaryPhoneValid =
          state.formData.primaryPhone &&
          checkValidPhoneCode(state.formData.primaryPhone);

        if (state.formData.secondaryPhone) {
          if (checkValidPhoneCode(state.formData.secondaryPhone)) {
            isPhonesFormValid.value = primaryPhoneValid;
          } else {
            isPhonesFormValid.value = false;
          }
        } else {
          isPhonesFormValid.value = primaryPhoneValid;
        }
        if (
          isInitialised.value &&
          !objectsAreSame(originalValue.value, state.formData)
        ) {
          console.log("its working");
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );

    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      let checkKeys = [
        "firstName",
        "middleName",
        "lastName",
        "suffix",
        "primaryPhone",
        "primaryPhoneExt",
        "secondaryPhone",
        "secondaryPhoneExt",
        "email",
        "userID",
        "railroadCompanyId",
        "railroadContactId",
        "address1",
        "address2",
        "city",
        "stateCode",
        "zipcode",
      ];

      for (let i of checkKeys) {
        if (x?.[i] !== y?.[i]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    const isSaveDisabled = computed(() => {
      return (
        !isFormValid.value ||
        !isEmailFormValid.value ||
        !isPhonesFormValid.value ||
        !isAddressFormValid.value ||
        !valueChanged.value
      );
    });
    return {
      ...toRefs(state),
      fieldValueChange,
      setFieldRule,
      debounce: createDebounce(),
      isFormValid,
      maxLengthRule1,
      maxLengthRule2,
      maxLengthRule999,
      minimumRule,
      minLengthRule1,
      requiredRule,
      numericRule,
      greaterThanEqualToZero,
      saveData,
      AddContact,
      UpdateContact,
      closeDialog,
      open,
      isNumber,
      validateEmail,
      phoneRule,
      zipRule,
      id,
      userData,
      allowAlphaOnly,
      keydownAllowNumberOnly,
      projectServiceStore,
      filterObject,
      isEmailFormValid,
      isPhonesFormValid,
      afterModalLeave,
      states,
      isAddressFormValid,
      isSaveDisabled,
    };
  },
};
