import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { reactive, ref, toRefs } from "vue";
export default {
  name: "ReturnReasonModal",
  components: { PrimaryButton, SecondaryButton },
  setup({ emit }, context) {
    const reason = ref(null);
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      heading: null,
      description: null,
      maxWidth: null,
      hideCancelButton: false,
    });
    const open = (heading, description, maxWidth, hideCancelButton) => {
      state.dialog = true;
      state.heading = heading;
      state.description = description;
      state.maxWidth = maxWidth ? Number(maxWidth) : null;
      state.hideCancelButton = hideCancelButton;
      state.reason = reason.value;
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const handleYes = () => {
      context.emit("emitReason", reason.value);
      reason.value = "";
      state.resolve(true);
      state.dialog = false;
    };

    const handleNo = () => {
      state.resolve(false);
      reason.value = "";
      state.dialog = false;
    };

    return {
      ...toRefs(state),
      open,
      handleYes,
      handleNo,
      reason,
    };
  },
};
