import { requiredRule } from "@/composables/validationRules";import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useRailroadProjectTeamStore } from "@/stores/RailroadProjectTeam";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  components: { PrimaryButton, SecondaryButton, LoadingOverlay },
  name: "RailroadProjectTeam",
  props: ["dotId"],
  setup(props, { emit }) {
    const railroadProjectTeamStore = useRailroadProjectTeamStore();
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const route = useRoute();
    const id = route.params.id;
    const isFormValid = ref(false);
    const formChanged = ref(false);
    const loading = ref(false);
    const apiData = ref({
      railroadProjectManager: [],
      railroadAgreementCoordinator: [],
      railroadInvoiceCoordinator: [],
      railroadFlaggingAndConstructionCoordinator: [],
    });
    const teamListCode = ref([
      "railroadProjectManager",
      "railroadAgreementCoordinator",
      "railroadInvoiceCoordinator",
      "railroadFlaggingAndConstructionCoordinator",
    ]);
    const teamList = ref([
      "Railroad Project Manager",
      "Railroad Agreement Coordinator",
      "Railroad Invoice Coordinator",
      "Railroad Flagging & Construction Coordinator",
    ]);
    const projectTeamList = ref([]);
    const selectedProjectTeamList = ref([]);
    const selectedProject = ref("");
    const showBannerMessage = ref(false);
    const saveClicked = ref(true);
    const isUserAccess = ref({
      read: true,
      update: true,
    });
    const accessCheck = async () => {
      const readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.RAILROAD_PROJECT_TEAM_PAGE_READ_ACCESS
      );
      const updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.RAILROAD_PROJECT_TEAM_PAGE_CRTUPD_ACCESS
      );
      isUserAccess.value.read = readResponse || false;
      isUserAccess.value.update = updateResponse || false;
    };
    const fetchDataHelper = async (response) => {
      teamList.value.forEach((team) => {
        const projectIndex = response.data.findIndex(
          (project) => project.projectTeamMemberRole === team && project?.userID
        );
        projectTeamList.value.push({
          mpmsNumber: Number(id),
          projectTeamMemberRole: team,
          userID: getNullOrData(projectIndex, response, "userID"),
          fullName: getNullOrData(projectIndex, response, "fullName"),
          firstName: getNullOrData(projectIndex, response, "firstName"),
          lastName: getNullOrData(projectIndex, response, "lastName"),
          emailAddress: getNullOrData(projectIndex, response, "emailAddress"),
          roles: getNullOrData(projectIndex, response, "roles"),
          displayName: getNullOrData(projectIndex, response, "displayName"),
          railroadCompanyId: getNullOrData(
            projectIndex,
            response,
            "railroadCompanyId"
          ),
        });
        selectedProjectTeamList.value.push(
          getNullOrData(projectIndex, response, "userID")
        );
      });
    };
    const getNullOrData = (projectIndex, response, key) => {
      return projectIndex >= 0 ? response.data[projectIndex]?.[key] : null;
    };
    onMounted(async () => {
      await accessCheck();
      await railroadProjectTeamStore.getLookup("railroadProjectManager", id);
      fetchData();
      console.log("teamListCode values", teamListCode.value);
      teamListCode.value.forEach((teamCode) => {
        fetchProjectLookupData(teamCode);
      });
    });
    const fetchData = () => {
      formChanged.value = false;
      selectedProjectTeamList.value = [];
      projectTeamList.value = [];
      axios
        .get(CONFIG_ENDPOINT.GET_RAILROAD_PROJECT_TEAM + id)
        .then(async (response) => {
          console.log("GET_RAILROAD_PROJECT_TEAM", response);
          await fetchDataHelper(response);
        })
        .catch(() => {
          teamList.value.forEach((team) => {
            projectTeamList.value.push({
              mpmsNumber: Number(id),
              projectTeamMemberRole: team,
              userID: null,
              fullName: null,
              firstName: null,
              lastName: null,
              emailAddress: null,
              roles: null,
              displayName: null,
              railroadCompanyId: null,
            });
          });
        });
    };
    const fetchProjectLookupData = async (project) => {
      if (!isUserAccess.value.update) return;
      selectedProject.value = project;
      let response = railroadProjectTeamStore.LookupData[project];
      if (!response?.length) {
        loading.value = true;
        await railroadProjectTeamStore.getLookup(project, id);
        response = railroadProjectTeamStore.LookupData[project];
        loading.value = false;
      }
      apiData.value[project] = response;
    };
    const inputFocus = (project) => {
      if (!isUserAccess.value.update) return;
      console.log("inputFocus", project);
      if (selectedProject.value !== project) {
        fetchProjectLookupData(project);
      }
    };
    const saveChanges = () => {
      if (!isUserAccess.value.update) return;
      saveClicked.value = true;
      const payload = projectTeamList.value.filter((project) => project.userID);
      axios
        .put(CONFIG_ENDPOINT.RAILROAD_PROJECT_TEAM_POST + id, payload)
        .then(() => {
          fetchData();
          showBannerMessage.value = true;
          emit("BannerUpdate", {
            bannerType: "success",
            bannerMessage: "Saved Successfully.",
            showBanner: showBannerMessage.value,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const updateDisplayName = (value, index) => {
      if (!isUserAccess.value.update) return;
      saveClicked.value = false;
      const lookupData = apiData.value[teamListCode.value[index]];
      const selectedIndex = lookupData.findIndex(
        (user) => user?.userID === value
      );
      delete lookupData[index].projectTeamMemberRole;
      delete lookupData[index].railroadCompanyId;
      const projectTeamMemberRole =
        projectTeamList.value[index]?.projectTeamMemberRole;
      const railroadCompanyId = projectTeamList.value[index]?.railroadCompanyId;
      projectTeamList.value[index] = {
        ...projectTeamList.value[index],
        ...lookupData[selectedIndex],
        userID: lookupData[selectedIndex]?.userID || null,
        displayName: lookupData[selectedIndex]?.displayName || null,
        mpmsNumber: Number(id),
        projectTeamMemberRole: projectTeamMemberRole,
        railroadCompanyId: railroadCompanyId,
      };
      formChanged.value = true;
    };
    onBeforeRouteLeave((to, from, next) => {
      if (!saveClicked.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure you want to leave the page?"
        );
        if (answer) {
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    });
    window.onbeforeunload = function () {
      if (!saveClicked.value) {
        return "You have unsaved data. Are you sure you want to leave the page?";
      }
    };
    return {
      apiData,
      teamList,
      inputFocus,
      projectTeamList,
      selectedProjectTeamList,
      teamListCode,
      selectedProject,
      saveChanges,
      fetchData,
      updateDisplayName,
      requiredRule,
      isFormValid,
      id,
      formChanged,
      isUserAccess,
      loading,
    };
  },
};
