import RailroadDocuments from "@/modules/manageproject/components/RailroadDocuments.vue";import RailroadWorkSummary from "@/modules/manageproject/components/RailroadWorkSummary.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";

import axios from "axios";
import { useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";

import { computed, onMounted, ref } from "vue";

export default {
  name: "TabsView",
  components: {
    RailroadWorkSummary,
    RailroadDocuments,
    BannerMessage,
  },
  props: ["railroad", "railroadMap", "railroadList"],
  emits: ["showBanner"],

  setup(props, { emit }) {
    let panel = ref([0, 1]);
    const tab = ref("railroadWorkSummary");
    const currentTab = ref("railroadWorkSummary");
    let showTabs = ref(true);
    const isInitialised = ref(false);
    const valueChanged = ref(false);
    const railroadList = ref([]);
    const railroadMap = ref({});
    const lookupData = ref({});
    const staticRailroad = ref(false);
    const selectedRailroad = ref(null);
    const route = useRoute();
    const id = route.params.id;
    const bannerMessage = ref("");
    const showBanner = ref(false);
    const isSuccess = ref(true);

    const showBannerMessage = (message) => {
      if (message == "Saved Successfully.") {
        isSuccess.value = true;
      } else {
        isSuccess.value = false;
      }
      bannerMessage.value = message;
      showBanner.value = true;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    const hideBannerMessage = () => {
      showBanner.value = false;
    };

    onMounted(async () => {
      isInitialised.value = false;
      valueChanged.value = false;
      fetchLookupData();
      createLookupMap();
    });

    const getCurrentTab = computed({
      get: () => {
        return currentTab?.value;
      },
    });
    const onTabChange = (eventTab) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          currentTab.value = eventTab;
        } else {
          currentTab.value = tab.value;
        }
      } else {
        tab.value = eventTab;
        currentTab.value = eventTab;
        valueChanged.value = false;
        isInitialised.value = false;
      }
    };

    function fetchLookupData() {
      axios
        .get(CONFIG_ENDPOINT.RAILROAD_WORK_SUMMARY_LOOKUP + id)
        .then((response) => {
          lookupData.value = response.data;
          createLookupMap();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const createLookupMap = () => {
      railroadList.value = [];
      for (let railroad in lookupData.value) {
        let temp = lookupData.value[railroad].railroadCompanyName;
        railroadList.value.push(temp);
        railroadMap.value[temp] = lookupData.value[railroad].railroadCompanyId;
      }
      if (railroadList.value.length == 1) {
        selectedRailroad.value = railroadList.value[0];
        staticRailroad.value = true;
      } else {
        staticRailroad.value = false;
      }

      console.log(selectedRailroad.value);
      console.log(railroadMap.value);
    };

    return {
      getCurrentTab,
      onTabChange,
      panel,
      tab,
      currentTab,
      showTabs,
      isInitialised,
      valueChanged,
      fetchLookupData,
      createLookupMap,
      railroadList,
      railroadMap,
      lookupData,
      selectedRailroad,
      staticRailroad,
      id,
      showBannerMessage,
      bannerMessage,
      showBanner,
      hideBannerMessage,
      isSuccess,
    };
  },
};
