export const ROUTE = Object.freeze({  GCMS_DETAILS: {
    path: "/GCMS/:id",
    title: "GCMS_DETAILS",
    icon: "home",
  },
  GCMS_HOME: {
    path: "/GCMS",
    title: "GCMS",
    icon: "home",
  },
  GRADEINVENTORY: {
    path: "/GCMS/gradeinventory",
    title: "gradeinventorylist",
    icon: "inventorylist",
  },

  EDIT_GCMS_DOT_INSPECTION_R2: {
    path: "/GCMS/DOT/:id/inspectionr2/:crossingInspectionId/:action",
    title: "inspectionr2",
    icon: "inventorylist",
  },
  DOT_DETAILS: {
    path: "/GCMS/DOT/:id",
    title: "dotdetails",
    icon: "detDetails",
  },
  VIEW_PROJECTS: {
    path: "/GCMS/ViewAllProjects",
    title: "viewAllProjects",
    icon: "inventorylist",
  },
  VIEW_TASKS: {
    path: "/GCMS/ViewAllTasks",
    title: "viewAllTasks",
    icon: "inventorylist",
  },
  FLAGGER_ACTIVITY_ADMIN: {
    path: "/GCMS/FlaggerActivityAdmin",
    title: "flaggerActivityAdmin",
    icon: "inventorylist",
  },
  PROJECT_FLAGGER_NEEDS: {
    path: "/GCMS/:id/projectFlaggerNeeds",
    title: "projectFlaggerNeeds",
    icon: "inventorylist",
  },
  ADD_RAILROADS: {
    path: "/GCMS/:id/addRailroads",
    title: "AddRailroads",
    icon: "inventorylist",
  },
  SAFETY_MILESTONES: {
    path: "/GCMS/:id/safetyMilestones",
    title: "SafetyMilestones",
    icon: "inventorylist",
  },
  MANAGE_FLAGGER_AND_RAILROADS: {
    path: "/GCMS/manage-flagger-and-railroads",
    title: "ManageFlaggerAndRailroads",
    icon: "inventorylist",
  },
  RAILROADS_ESTIMATES: {
    path: "/GCMS/railroad-estimates",
    title: "RailroadEstimates",
    icon: "inventorylist",
  },
  EDIT_RAILROADS_ESTIMATES: {
    path: "/GCMS/railroad-estimates/edit/:cid/:eid",
    title: "EditRailroadEstimates",
    icon: "inventorylist",
  },
  MPMS_PROJECTS: {
    path: "/GCMS/mpmsProjects",
    title: "mpmsProjects",
    icon: "inventorylist",
  },
  ADVANCED_SEARCH: {
    path: "/GCMS/advancedSearch",
    title: "advancedSearch",
    icon: "inventorylist",
  },
  COMPLETED_CROSSINGS_INSPECTIONS: {
    path: "/GCMS/completed-crossings-inspections",
    title: "completedCrossingsInspections",
    icon: "inventorylist",
  },
  DISTRICT_OFFICE_LIST: {
    path: "/GCMS/districtOfficeList",
    title: "districtOfficeList",
    icon: "inventorylist",
  },
  MAINTAIN_SIGNATURE_TEMPLATE_LIST: {
    path: "/GCMS/maintain-signature-template",
    title: "maintain-signature-template",
    icon: "inventorylist",
  },
  MANAGE_CONSULTING_COMPANIES_LIST: {
    path: "/GCMS/manage-consulting-companies",
    title: "manage-consulting-companies",
    icon: "inventorylist",
  },
  MANAGE_CONSULTING_COMPANIES_LIST: {
    path: "/GCMS/manage-consulting-companies",
    title: "manage-consulting-companies",
    icon: "inventorylist",
  },
  GET_CONSULTING_COMPANY: {
    path: "/GCMS/manage-consulting-companies/:id",
    title: "manage-consulting-companies",
    icon: "inventorylist",
  },
  ADD_MANAGE_RAILROAD_COMPANIES: {
    path: "/GCMS/manage-railroad-companies/add",
    title: "manage-railroad-companies",
    icon: "inventorylist",
  },
  EDIT_MANAGE_RAILROAD_COMPANIES: {
    path: "/GCMS/manage-railroad-companies/:action/:id/:railroad",
    title: "manage-railroad-companies",
    icon: "inventorylist",
  },
  MANAGE_RAILROAD_COMPANIES: {
    path: "/GCMS/manage-railroad-companies",
    title: "manage-railroad-companies",
    icon: "inventorylist",
  },
  ASSIGN_DISTRICTS: {
    path: "/GCMS/assign-districts",
    title: "assign-districts",
    icon: "assignDistricts",
  },
  PROJECT_INVOLVEMENT: {
    path: "/GCMS/:id/project-involvement",
    title: "project-involvement",
    icon: "inventorylist",
  },
  PARTIES_OF_RECORD: {
    path: "/GCMS/:id/parties-of-record",
    title: "parties-of-record",
    icon: "inventorylist",
  },
  WORKFLOW_NOTIFICATIONS: {
    path: "/GCMS/:id/workflow-notifications",
    title: "workflow-notifications",
    icon: "inventorylist",
  },
  PUC_COMMUNICATIONS: {
    path: "/GCMS/:id/puc-communications",
    title: "puc-communications",
    icon: "inventorylist",
  },
  RAILROAD_PLAN_REVIEW: {
    path: "/GCMS/:id/railroad-plan-review",
    title: "railroad-plan-review",
    icon: "inventorylist",
  },
  PROJECT_TEAM: {
    path: "/GCMS/:id/project-team",
    title: "project-team",
    icon: "inventorylist",
  },
  RAILROAD_PROJECT_TEAM: {
    path: "/GCMS/:id/railroad-project-team",
    title: "railroad-project-team",
    icon: "inventorylist",
  },
  PROJECT_MILESTONES: {
    path: "/GCMS/:id/project-milestones",
    title: "project-milestones",
    icon: "inventorylist",
  },
  RAILROAD_CERTIFICATION: {
    path: "/GCMS/:id/railroad-certification",
    title: "railroadCertification",
    icon: "inventorylist",
  },
  PROJECT_DOCUMENTS: {
    path: "/GCMS/:id/project-document",
    title: "projectDocumentation",
    icon: "inventorylist",
  },
  PROJECT_FOLLOWERS: {
    path: "/GCMS/:id/project-followers",
    title: "projectFollowers",
    icon: "inventorylist",
  },
  CONSTRUCTION_PHASE: {
    path: "/GCMS/:id/project-gradecrossing-inventory-updates",
    title: "constructionPhase",
    icon: "inventorylist",
  },

  CONSULTANT_AGREEMENT: {
    path: "/GCMS/consultant-agreement",
    title: "consultant-agreement",
    icon: "consultantAgreement",
  },

  PARENT_SUBSIDIARIES: {
    path: "/GCMS/parent-subsidiaries",
    title: "parent-subsidiaries",
    icon: "consultantAgreement",
  },
});
