<template>
  <div class="expanderOverLay main-content">
    <div
      v-if="dashboardUserRoleMsg"
      class="railroadError"
      style="width: 100%; margin: 0; margin-bottom: 10px"
    >
      <v-icon icon="mdi-alert"></v-icon>
      <span v-html="parsedDashboardUserRoleMsg"></span>
    </div>
    <div
      v-if="showRailroadErrorBanner"
      class="railroadError"
      style="width: 100%; margin: 0; margin-bottom: 10px"
    >
      <v-icon icon="mdi-alert"></v-icon>
      {{ railroadErrorMessage }}
    </div>
    <div
      v-if="assignDistrictData && assignDistrictData.length > 0"
      class="railroadError"
      style="width: 100%; margin: 0; margin-bottom: 10px"
    >
      <v-icon icon="mdi-alert"></v-icon>
      {{ assignDistrictData }}
    </div>
    <div class="w-full d-flex">
      <OnlineHelpPOC :helpKey="'DASH_HELP'"></OnlineHelpPOC>
    </div>
    <router-view></router-view>
    <div class="mt-20">
      <ProjectIdentifierLegend></ProjectIdentifierLegend>
    </div>
    <MyTasks></MyTasks>
    <RecentProjects></RecentProjects>
    <ActiveProjects></ActiveProjects>
  </div>
</template>
<script>
import ActiveProjects from "@/modules/gcmsmain/dashboard/components/ActiveProjects.vue";
import MyTasks from "@/modules/gcmsmain/dashboard/components/MyTasks.vue";
import RecentProjects from "@/modules/gcmsmain/dashboard/components/RecentProjects.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import ProjectIdentifierLegend from "@/modules/shared/components/ProjectIdentifierLegend.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import { useUserStore } from "@/stores/userStore";
import axios from "axios";
import { onMounted, ref, computed } from "vue";
import { CONFIG_ENDPOINT } from "../constants/Endpoints";
export default {
  name: "DashboardView",
  components: {
    ActiveProjects: ActiveProjects,
    RecentProjects: RecentProjects,
    MyTasks: MyTasks,
    ProjectIdentifierLegend: ProjectIdentifierLegend,
    OnlineHelpPOC: OnlineHelpPOC,
    BannerMessage,
  },
  setup() {
    const showRailroadErrorBanner = ref(null);
    const railroadErrorMessage = ref("");
    const dashboardUserRoleMsg = ref("");
    const assignDistrictData = ref(null);
    const userStore = useUserStore();
    const hideLinkedUserMessage = () => {
      userStore.setLinkedUserMessage("");
    };
    const hideDashboardMessage = () => {
      userStore.setDashboardMessage("");
    };
    onMounted(() => {
      if (userStore.matchedRailroadErrorBanner) {
        showRailroadErrorBanner.value = true;
        railroadErrorMessage.value = userStore.matchedRailroadErrorMessage;
      }
      checkLinkedUser();
      axios
        .get(CONFIG_ENDPOINT.ASSIGN_DISTRICTS)
        .then((res) => {
          assignDistrictData.value = res.data.data;
        })
        .catch((err) => {});
    });
    const checkLinkedUser = async () => {
      try {
        const response = userStore.getRailroadUser();
        if (response) {
          console.log(
            "User is a railroad. Skipping all railroad-specific APIs."
          );
          axios
            .get(CONFIG_ENDPOINT.DASHBOARD_USER_MESSAGE)
            .then((res) => {
              console.log("CONFIG_ENDPOINT.DASHBOARD_USER_MESSAGE", res);
              dashboardUserRoleMsg.value = res.data.data.businessMessage;
            })
            .catch((err) => {});
        } else {
          console.log(
            "User is NOT a railroad. Skipping all railroad-specific APIs."
          );
        }
      } catch (err) {
        console.error("Error checking linked user:", err);
      }
    };
    const parsedDashboardUserRoleMsg = computed(() => {
      if (!dashboardUserRoleMsg.value) return "";
      const message = dashboardUserRoleMsg.value;
      const emailRegex = /\(link=([^)]+)\)/;
      const match = message.match(emailRegex);
      if (match) {
        const email = match[1];
        return message.replace(
          emailRegex,
          `(Link: <a href="mailto:${email}" style="color: #3568A7; text-decoration: underline;">${email}</a>)`
        );
      }
      return message;
    });
    return {
      assignDistrictData,
      userStore,
      hideLinkedUserMessage,
      hideDashboardMessage,
      showRailroadErrorBanner,
      railroadErrorMessage,
      dashboardUserRoleMsg,
      parsedDashboardUserRoleMsg,
    };
  },
};
</script>