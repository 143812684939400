<template>
  <div class="w-full d-flex">
    <OnlineHelpPOC :helpKey="'PJCRTLSHLP'"></OnlineHelpPOC>
  </div>
  <div class="allProjectsHeading">Create Grade Crossing Project from MPMS</div>

  <v-card class="p-64">
    <v-form v-model="mpmsIdFormValid" class="mpmsSubheader">
      <v-label class="subHeaderText2"
        >Enter an MPMS# or select an MPMS from the list to create a Grade
        Crossing project.</v-label
      >
      <label class="createLabel">Enter MPMS# to Create: </label>
      <div class="createRow">
        <v-text-field
          v-model="mpmsValue"
          @keydown.enter="submitForm"
          :rules="[mpmsRequiredRule, mpmsLengthRule]"
          @keydown="keydownAllowNumberOnly($event)"
          :maxlength="9"
          class="small-text-field2"
        ></v-text-field>

        <PrimaryButton
          :buttonText="'Create'"
          @click="submitForm"
          :disabled="!mpmsIdFormValid || !mpmsValue || mpmsValue?.length === 0"
        >
        </PrimaryButton>
      </div>
      <div
        class="project-invalid-mpms"
        v-show="showInvalidMPMS && lastEnteredMpmsValue"
      >
        {{ errorMessage }}
      </div>
    </v-form>
    <FeatureTable
      :headers="headers"
      :items="inventorylist"
      :showExportButton="true"
      :exportFilName="'MPMS Projects'"
      :showClearFilterButton="true"
      :tableHeader="''"
      :itemsPerPage="paginationData.items"
      :clickable="true"
      :isCreateProject="true"
      :linkedPagination="true"
      :totalItems="paginationData.total"
      :lookupData="lookupData"
      :oldMultipleFilter="multipleFilter"
      :isDynamicExport="true"
      :showInformationText="true"
      :noDataAvailableText="noDataAvailableText"
      @updatePaginationPerPage="updatePaginationPerPage"
      @pageChange="pageChange"
      @sortUpdate="sortUpdate"
      @textFilterChange="textFilterChange"
      @multipleFiltersChange="multipleFiltersChange"
      @clearAllFilter="clearAllFilter"
      @exportButtonClicked="exportButtonClicked"
    >
    </FeatureTable>
  </v-card>

  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
<script>
import mpmsProjects from "../scripts/mpmsProjects.js";
export default mpmsProjects;
</script>
<script></script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
