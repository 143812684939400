import DropZone from "@/modules/shared/components/DropZone.vue";import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import UploadedFilePreview from "@/modules/shared/components/UploadedFilePreview.vue";
import useFileList from "@/modules/shared/scripts/fileList";
import { computed, ref, watch } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import {
  ACCEPTED_DOT_DROPDOWN_DOC_TYPE,
  ACCEPTED_RAILROAD_DROPDOWN_DOC_TYPE,
} from "../../../constants/constantValues";
export default {
  name: "UploadScopingFieldViewDocument",
  components: { DropZone, PrimaryButton, UploadedFilePreview },
  emits: [
    "uploadFilesCall",
    "isUploadButtonDisabled",
    "uploadedFiles",
    "valueChangedEvent",
  ],
  props: {
    uploadedDocumentsLength: { type: Number, default: 0 },
    mpmsId: { type: String, default: "" },
    saveClicked: { type: Boolean, default: null },
    checkMaxFileSize: { type: Boolean, default: false },
    checkMaximumFilesUploaded: { type: Boolean, default: false },
    maximumFilesUploadRange: { type: Number, default: 5 },
    showPreviewRailroadColumn: { type: Boolean, default: false },
    showPreviewDOTColumn: { type: Boolean, default: false },
    isDynamicDocumentType: { type: Boolean, default: false },
    staticDocumentTypeList: { default: [] },
    staticDocumentTypeValue: { type: String, default: "" },
    isRailroadDropdownStatic: { type: Boolean, default: false },
    staticRailroadDropdownList: { default: [] },
    staticRailroadDropdownValue: { type: String, default: "" },
    staticDOTDropdownList: { default: [] },
    staticDOTDropdownValue: { type: String, default: "" },
    previewDocTypeUrl: { type: String, default: "" },
    showDocTypeText: { type: Boolean, default: false },
    showRailroadTypeText: { type: Boolean, default: false },
    isRailroadDropdownDisabled: { type: Boolean, default: false },
    isRailroadWorkSummary: { type: Boolean, default: false },
    isRailroadWorkSummaryDOTList: { default: [] },
    isPageUpload: { type: Boolean, default: false },
    isContractorBidSafetyMilestone: { type: Boolean, default: false },
    hideUploadButton: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const { files, addFiles, removeFile, updateFiles } = useFileList();
    const disableUpload = ref(true);

    const acceptedRailroadDropdownDocTypes = ref(
      ACCEPTED_RAILROAD_DROPDOWN_DOC_TYPE
    );

    let valueChanged = ref(false);
    const acceptedDotDropdownDocType = ref(ACCEPTED_DOT_DROPDOWN_DOC_TYPE);
    watch(
      () => [files],
      () => {
        if (files?.value.length) {
          if (props?.showPreviewDOTColumn || props?.showPreviewRailroadColumn) {
            checkMilestoneTableValid();
          } else if (
            props?.isDynamicDocumentType &&
            files?.value?.filter((file) => file.file.docType)?.length !=
              files?.value.length
          ) {
            disableUpload.value = true;
          } else {
            disableUpload.value = false;
          }
        } else {
          disableUpload.value = true;
        }
        emit("uploadedFiles", files);
      },
      { deep: true }
    );
    const isUploaddisabled = computed({
      get: () => {
        return disableUpload.value || !files.value.length;
      },
    });

    watch(
      () => [isUploaddisabled],
      () => {
        emit("isUploadButtonDisabled", isUploaddisabled.value);
      },
      { deep: true }
    );

    watch(
      () => [props?.saveClicked],
      () => {
        if (props?.saveClicked) {
          onUploadClick();
        }
      },
      { deep: true }
    );
    function checkMilestoneTableValid() {
      let filesWithDocType = files?.value?.filter((file) => file.file.docType);
      if (filesWithDocType.length === files?.value?.length) {
        checkRailroadDotValidation();
      } else {
        disableUpload.value = true;
      }
    }
    function checkRailroadDotValidation() {
      /* RAILROAD CHECK */
      let isRailroadValid = isRailroadColumnValid();

      /* DOT CHECK */
      let isDotValid = isDotColumnValid();
      if (props?.showPreviewRailroadColumn && props?.showPreviewDOTColumn) {
        disableUpload.value = !isRailroadValid || !isDotValid;
      } else if (
        props?.showPreviewRailroadColumn ||
        props?.showPreviewDOTColumn
      ) {
        if (props?.showPreviewRailroadColumn) {
          disableUpload.value = !isRailroadValid;
        } else if (props?.showPreviewDOTColumn) {
          disableUpload.value = !isDotValid;
        } else {
          disableUpload.value = !isRailroadValid || !isDotValid;
        }
      } else {
        disableUpload.value = !isRailroadValid || !isDotValid;
      }
    }
    function isRailroadColumnValid() {
      let isRailroadValid = false;
      if (props?.showPreviewRailroadColumn) {
        let availableRailroadList = files?.value?.filter((file) =>
          showRailroadDropdown(file.file.docType)
        );
        if (availableRailroadList.length) {
          let filesWithRailroad = files?.value?.filter(
            (file) => file.payloadData.railroad
          );
          isRailroadValid =
            filesWithRailroad?.length === availableRailroadList?.length;
        } else {
          isRailroadValid = true;
        }
      }
      return isRailroadValid;
    }
    function isDotColumnValid() {
      let isDotValid = false;
      if (props?.showPreviewDOTColumn) {
        let availableDotList = files?.value?.filter((file) =>
          showDOTDropdown(file.file.docType)
        );
        if (availableDotList.length) {
          let filesWithDot = files?.value?.filter(
            (file) => file.payloadData.dot
          );
          isDotValid = filesWithDot?.length === availableDotList?.length;
        } else {
          isDotValid = true;
        }
      }
      return isDotValid;
    }
    function showRailroadDropdown(docType) {
      return acceptedRailroadDropdownDocTypes.value.includes(docType);
    }
    function showDOTDropdown(docType) {
      return acceptedDotDropdownDocType.value.includes(docType);
    }
    const mpmsId = computed({
      get: () => {
        return props?.mpmsId;
      },
    });
    const uploadedDocumentsLength = computed({
      get: () => {
        return props?.uploadedDocumentsLength;
      },
    });
    const checkMaxFileSize = computed({
      get: () => {
        return props?.checkMaxFileSize;
      },
    });
    const checkMaximumFilesUploaded = computed({
      get: () => {
        return props?.checkMaximumFilesUploaded;
      },
    });
    const maximumFilesUploadRange = computed({
      get: () => {
        return props?.maximumFilesUploadRange;
      },
    });

    const showPreviewRailroadColumn = computed({
      get: () => {
        return props?.showPreviewRailroadColumn;
      },
    });
    const showPreviewDOTColumn = computed({
      get: () => {
        return props?.showPreviewDOTColumn;
      },
    });
    const isDynamicDocumentType = computed({
      get: () => {
        return props?.isDynamicDocumentType;
      },
    });
    const staticDocumentTypeList = computed({
      get: () => {
        return props?.staticDocumentTypeList;
      },
    });
    const staticDocumentTypeValue = computed({
      get: () => {
        return props?.staticDocumentTypeValue;
      },
    });
    const showDocTypeText = computed({
      get: () => {
        return props?.showDocTypeText;
      },
    });
    const isRailroadDropdownStatic = computed({
      get: () => {
        return props?.isRailroadDropdownStatic;
      },
    });
    const staticRailroadDropdownList = computed({
      get: () => {
        return props?.staticRailroadDropdownList;
      },
    });
    const staticRailroadDropdownValue = computed({
      get: () => {
        return props?.staticRailroadDropdownValue;
      },
    });

    const isRailroadWorkSummaryDOTList = computed({
      get: () => {
        return props?.isRailroadWorkSummaryDOTList;
      },
    });

    const staticDOTDropdownList = computed({
      get: () => {
        return props?.staticDOTDropdownList;
      },
    });
    const staticDOTDropdownValue = computed({
      get: () => {
        return props?.staticDOTDropdownValue;
      },
    });

    const previewDocTypeUrl = computed({
      get: () => {
        return props?.previewDocTypeUrl;
      },
    });
    const showRailroadTypeText = computed({
      get: () => {
        return props?.showRailroadTypeText;
      },
    });
    const isRailroadDropdownDisabled = computed({
      get: () => {
        return props?.isRailroadDropdownDisabled;
      },
    });

    const isRailroadWorkSummary = computed({
      get: () => {
        return props?.isRailroadWorkSummary;
      },
    });

    const isContractorBidSafetyMilestone = computed({
      get: () => {
        return props?.isContractorBidSafetyMilestone;
      },
    });

    const hideUploadButton = computed({
      get: () => {
        return props?.hideUploadButton;
      },
    });

    function onInputChange(e) {
      const files = e;
      files?.forEach((file) => {
        if (props?.checkMaxFileSize) {
          if (file.size / 1024 / 1024 < 500) {
            addFiles([file]);
          }
        } else {
          addFiles([file]);
        }
      });
      if (files?.length) {
        valueChanged.value = true;
        emit("valueChangedEvent", true);
      } else {
        valueChanged.value = false;
        emit("valueChangedEvent", false);
      }
      e = null; // reset so that selecting the same file again will still cause it to fire this change
      document.getElementById("preview-section").scrollIntoView();
    }

    onBeforeRouteLeave((to, from, next) => {
      if (props?.isPageUpload && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        next();
      }
    });
    window.onbeforeunload = function () {
      if (valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    function onUploadClick() {
      emit("uploadFilesCall", files);
      files.value = [];
      valueChanged.value = false;
      emit("valueChangedEvent", false);
      updateFiles([]);
    }
    return {
      onInputChange,
      onUploadClick,
      files,
      addFiles,
      removeFile,
      updateFiles,
      checkMaximumFilesUploaded,
      checkMaxFileSize,
      disableUpload,
      uploadedDocumentsLength,
      mpmsId,
      isUploaddisabled,
      showPreviewRailroadColumn,
      showPreviewDOTColumn,
      isDynamicDocumentType,
      staticDocumentTypeList,
      staticDocumentTypeValue,
      previewDocTypeUrl,
      isRailroadDropdownStatic,
      staticRailroadDropdownList,
      staticRailroadDropdownValue,
      maximumFilesUploadRange,
      showDocTypeText,
      showRailroadTypeText,
      isRailroadDropdownDisabled,
      staticDOTDropdownList,
      staticDOTDropdownValue,
      isRailroadWorkSummary,
      isRailroadWorkSummaryDOTList,
      isContractorBidSafetyMilestone,
      hideUploadButton,
    };
  },
};
