import useIconsFunctions from "@/composables/projectIdentifiersIcons";import useSharedFunctions from "@/composables/shared";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent from "@/modules/project/components/ChangeProjectStatusDialog.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import SharedConfirmationDialog from "@/modules/shared/components/SharedConfirmationDialog.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import { useUserStore } from "@/stores/userStore";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useRefreshDataStore } from "../../../stores/dataResresh";
export default {
  name: "MpmsProjectDetails",
  components: {
    SecondaryButton,
    BannerMessage,
    DialogComponent,
    SharedConfirmationDialog,
    OnlineHelpPOC,
  },
  setup(props) {
    const userRefreshDataStore = useRefreshDataStore();
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const holdUnholdProjectDialog = ref(null);
    const ChangeProjectStatusDialog = ref(null);
    const { getIdentifierIcon } = useIconsFunctions();
    const userStore = useUserStore();
    const user = computed(() => userStore.loggedInUser);
    const isAuthenticated = computed(() => userStore.isAuthenticated);
    const route = useRoute();
    const projectDetails = ref(null);
    const projectIdentifierIcons = ref({});
    const safetyProjectFlag = ref(false);
    const highwayProjectFlag = ref(false);
    const { toTitleCase } = useSharedFunctions();
    const SuccessBannerData = ref(null);
    const ErrorBannerData = ref(null);
    const menuItems = ref([]);
    const isPucApprovalRequired = ref(false);
    const isChangeStatusAccess = ref(false);
    const isHoldButtonAccess = ref({
      read: false,
      update: false,
    });
    const isFollowUnfollowButtonAccess = ref({
      read: false,
      update: false,
    });

    const isReopenButtonAccess = ref({
      update: false,
    });
    const isUserAddRailroadAccess = ref({ update: false });

    const tab = computed({
      get: () => {
        return props?.tab;
      },
    });
    const projectId = ref(route.params.id);
    const menuTitle = computed({
      get: () => {
        return props?.menuTitle || "MANAGE PROJECT";
      },
    });
    const open = ref(["Users"]);
    let currentTab = ref("projectInformation");
    watch(
      route,
      (to) => {
        projectId.value = to.params.id;
        console.log("route.path", route.path, route.path?.split("/"));
        if (route.path?.includes("/GCMS/" + projectId.value + "/")) {
          currentTab.value = route.path?.split("/")[3];
        }
        if (route.path === "/GCMS/" + projectId.value && to.name === "Tabs") {
          getData();
        }
      },
      { flush: "pre", immediate: true, deep: true }
    );
    watch(
      () => [
        isChangeStatusAccess.value,
        isHoldButtonAccess.value,
        isFollowUnfollowButtonAccess.value,
        isReopenButtonAccess.value,
        isUserAddRailroadAccess.value,
      ],
      () => {
        menuItems.value = [
          {
            showItem: true,
            subtitle: "Project Documents",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.PROJECT_DOCUMENTS_URL,
          },
          {
            showItem: true,
            subtitle: "Project Followers",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.PROJECT_FOLLOWERS,
          },
          {
            showItem: true,
            subtitle: "Project Workflow Notifications",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.WORKFLOW_NOTIFICATIONS,
          },
          {
            showItem: isUserAddRailroadAccess.value.update,
            subtitle: "Add Railroads",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.ADD_RAILROADS,
          },
          {
            showItem: true,
            subtitle: "Project Involvement",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.PROJECT_INVOLVEMENT_ROUTE,
          },
          {
            showItem: true,
            subtitle: "Railroad Work Summary",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.RAILROAD_WORK_SUMMARY,
          },
          {
            showItem: safetyProjectFlag.value,
            subtitle: "Contractor Bids",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.CONTRACTOR_BID,
          },
          {
            showItem: safetyProjectFlag.value,
            subtitle: "Safety Project Milestones",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.SAFETY_MILESTONES,
          },
          {
            subtitle: "Railroad Plan Review",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.RAILROAD_PLAN_REVIEW,
            showItem: highwayProjectFlag.value,
          },
          {
            showItem: true,
            active: false,
            isSubMenus: true,
            title: "PUC Involvement",
            items: [
              {
                showItem: isPucApprovalRequired.value,
                hideItem: safetyProjectFlag.value,
                subtitle: " PUC Communications",
                value:
                  CONFIG_ENDPOINT.GCMS +
                  projectId.value +
                  CONFIG_ENDPOINT.PUC_COMMUNICATIONS,
              },
              {
                showItem: isPucApprovalRequired.value,
                subtitle: "PUC Parties of Record",
                value:
                  CONFIG_ENDPOINT.GCMS +
                  projectId.value +
                  CONFIG_ENDPOINT.PARTIES_OF_RECORD,
              },
            ],
          },
          {
            showItem: true,
            active: false,
            isSubMenus: true,
            title: "Estimates, Agreements, Invoices",
            items: [
              {
                showItem: true,
                subtitle: "Railroad Estimates",
                value:
                  CONFIG_ENDPOINT.GCMS +
                  projectId.value +
                  CONFIG_ENDPOINT.RAILROAD_ESTIMATES,
              },
            ],
          },
          {
            showItem: true,
            subtitle: "Flagger Needs",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.PROJECT_FLAGGER_NEEDS,
          },
          {
            showItem: highwayProjectFlag.value,
            subtitle: "Railroad Certification",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.RAILROAD_CERTIFICATION,
          },
          {
            showItem: true,
            subtitle: "Project Team",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.PROJECT_TEAM,
          },
          {
            showItem: true,
            subtitle: "Railroad Project Team",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.RAILROAD_PROJECT_TEAM,
          },
          {
            showItem: true,
            subtitle: "Project History",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.PROJECT_HISTORY,
          },
          {
            showItem: true,
            subtitle: "Project Crossing Inventory Updates",
            value:
              CONFIG_ENDPOINT.GCMS +
              projectId.value +
              CONFIG_ENDPOINT.PROJECT_CROSSINGINVENTORYUPDATES,
          },
        ];
        menuItems.value = menuItems.value?.map((item, index) => {
          return {
            ...item,
            items: item?.items?.filter((subItem) => !subItem?.hideItem),
          };
        });
        menuItems.value = menuItems.value?.filter((item, index) => {
          return item.showItem;
        });
      },
      { deep: true }
    );
    onMounted(async () => {
      await checkPageAuthority();
      await onHoldAccessCheck();
      await followUnfollowAccessCheck();
      await reOpenProjectAccessCheck();
      await railroadAccessCheck();
      projectId.value = route.params.id;
      getData();
    });

    const onHoldAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_ONHOLD_BUTTON_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_ONHOLD_BUTTONCRTUPDT_ACCESS
      );
      isHoldButtonAccess.value.read = readResponse;
      isHoldButtonAccess.value.update = updateResponse;
    };
    const followUnfollowAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.COMMON_PROJECT_INFORMATION_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_FOLLOW_CRTUPD_ACCESS
      );
      isFollowUnfollowButtonAccess.value.read = readResponse;
      isFollowUnfollowButtonAccess.value.update = updateResponse;
    };

    const reOpenProjectAccessCheck = async () => {
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_REOPEN_BUTTON_ACCESS
      );
      isReopenButtonAccess.value.update = updateResponse;
    };
    const railroadAccessCheck = async () => {
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.ADD_RAILROAD_PROJECT_PAGE_CRTUPD_ACCESS
      );
      isUserAddRailroadAccess.value.update = updateResponse || false;
      console.log(
        "isUserAddRailroadAccess",
        isUserAddRailroadAccess.value,
        isUserAddRailroadAccess.value.update
      );
    };

    const updateData = () => {
      getData();
    };

    const checkPageAuthority = async () => {
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.MANUALLY_MODIFY_PROJECT_STATUS_CRTUPD_ACCESS
      );
      isChangeStatusAccess.value = updateResponse;
    };
    const getDataHelper = () => {
      projectIdentifierIcons.value = {};
      for (let identifier in projectDetails.value.projectIdentifier) {
        let tempId = projectDetails.value.projectIdentifier[identifier];
        if (tempId == "Safety project" || tempId == "Safety Project") {
          safetyProjectFlag.value = true;
        }
        if (
          tempId === "Highway Bridge project" ||
          tempId === "Highway Bridge Project"
        ) {
          highwayProjectFlag.value = true;
        }
        projectIdentifierIcons.value[identifier] = getIdentifierIcon(tempId);
      }

      isPucApprovalRequired.value =
        projectDetails.value.pucReviewApprovalRequired;
      menuItems.value = [
        {
          showItem: true,
          subtitle: "Project Documents",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.PROJECT_DOCUMENTS_URL,
        },
        {
          showItem: true,
          subtitle: "Project Followers",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.PROJECT_FOLLOWERS,
        },
        {
          showItem: true,
          subtitle: "Project Workflow Notifications",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.WORKFLOW_NOTIFICATIONS,
        },
        {
          showItem: isUserAddRailroadAccess.value.update,
          subtitle: "Add Railroads",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.ADD_RAILROADS,
        },
        {
          showItem: true,
          subtitle: "Project Involvement",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.PROJECT_INVOLVEMENT_ROUTE,
        },
        {
          showItem: true,
          subtitle: "Railroad Work Summary",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.RAILROAD_WORK_SUMMARY,
        },
        {
          showItem: safetyProjectFlag.value,
          subtitle: "Contractor Bids",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.CONTRACTOR_BID,
        },
        {
          showItem: safetyProjectFlag.value,
          subtitle: "Safety Project Milestones",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.SAFETY_MILESTONES,
        },
        {
          subtitle: "Railroad Plan Review",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.RAILROAD_PLAN_REVIEW,
          showItem: highwayProjectFlag.value,
        },
        {
          showItem: true,
          active: false,
          isSubMenus: true,
          title: "PUC Involvement",
          items: [
            {
              showItem: isPucApprovalRequired.value,
              hideItem: safetyProjectFlag.value,
              subtitle: " PUC Communications",
              value:
                CONFIG_ENDPOINT.GCMS +
                projectId.value +
                CONFIG_ENDPOINT.PUC_COMMUNICATIONS,
            },
            {
              showItem: isPucApprovalRequired.value,
              subtitle: "PUC Parties of Record",
              value:
                CONFIG_ENDPOINT.GCMS +
                projectId.value +
                CONFIG_ENDPOINT.PARTIES_OF_RECORD,
            },
          ],
        },
        {
          showItem: true,
          active: false,
          isSubMenus: true,
          title: "Estimates, Agreements, Invoices",
          items: [
            {
              showItem: true,
              subtitle: "Railroad Estimates",
              value:
                CONFIG_ENDPOINT.GCMS +
                projectId.value +
                CONFIG_ENDPOINT.RAILROAD_ESTIMATES,
            },
          ],
        },
        {
          showItem: true,
          subtitle: "Flagger Needs",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.PROJECT_FLAGGER_NEEDS,
        },
        {
          showItem: highwayProjectFlag.value,
          subtitle: "Railroad Certification",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.RAILROAD_CERTIFICATION,
        },
        {
          showItem: true,
          subtitle: "Project Team",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.PROJECT_TEAM,
        },
        {
          showItem: true,
          subtitle: "Railroad Project Team",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.RAILROAD_PROJECT_TEAM,
        },
        {
          showItem: true,
          subtitle: "Project History",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.PROJECT_HISTORY,
        },
        {
          showItem: true,
          subtitle: "Project Crossing Inventory Updates",
          value:
            CONFIG_ENDPOINT.GCMS +
            projectId.value +
            CONFIG_ENDPOINT.PROJECT_CROSSINGINVENTORYUPDATES,
        },
      ];
      menuItems.value = menuItems.value?.map((item, index) => {
        return {
          ...item,
          items: item?.items?.filter((subItem) => !subItem?.hideItem),
        };
      });
      menuItems.value = menuItems.value?.filter((item, index) => {
        return item.showItem;
      });
    };
    function getData() {
      axios
        .get(
          CONFIG_ENDPOINT.PROJECT_INFO_INFO +
            projectId.value +
            "?userID=" +
            user.value.sub
        )
        .then((response) => {
          projectDetails.value = response.data;
          getDataHelper();
        })
        .catch((err) => {
          console.log(err);
          projectDetails.value = null;
        });
    }
    const BannerUpdate = (event) => {
      if (event?.showBanner) {
        updateData();
      }
      if (event?.bannerType === "success") {
        SuccessBannerData.value = event;
      } else if (event?.bannerType === "error") {
        ErrorBannerData.value = event;
      }
    };
    const hideSuccessBanner = () => {
      SuccessBannerData.value = null;
    };
    const hideErrorBannerMessage = () => {
      ErrorBannerData.value = null;
    };
    const changeStatusButtonClicked = () => {
      ChangeProjectStatusDialog.value
        .open("Assign District", projectDetails.value)
        .then((resolve) => {
          if (resolve) {
            if (resolve.success) {
              SuccessBannerData.value = {
                bannerType: "success",
                bannerMessage: "Saved Successfully.",
                showBanner: true,
              };
              ErrorBannerData.value = null;
            } else {
              ErrorBannerData.value = {
                bannerType: "error",
                bannerMessage:
                  resolve?.errorMsg?.response?.data?.businessMessage || "",
                showBanner: true,
                hasList: false,
                errorList: [],
              };
              SuccessBannerData.value = null;
            }
            getData();
          }
        });
    };
    const refreshOnFollowButtonClick = ref(false);
    const followPageRefreshed = (event) => {
      refreshOnFollowButtonClick.value = false;
    };
    const followUnfollowButtonClicked = () => {
      axios
        .post(
          CONFIG_ENDPOINT.FOLLOW_UNFOLLOW_MPMS_PROJECT +
            projectId.value +
            "?userID=" +
            user.value.sub,
          {
            followProject: projectDetails.value.followProject,
            unFollowProject: projectDetails.value.unFollowProject,
          }
        )
        .then((response) => {
          const responseData = response.data.data;
          if (responseData) {
            projectDetails.value.followProject = responseData.followProject;
            projectDetails.value.unFollowProject = responseData.unFollowProject;
          }
          getData();
          refreshOnFollowButtonClick.value = true;
          console.log(
            "refreshOnFollowButtonClick",
            refreshOnFollowButtonClick.value
          );
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const holdUnHoldButtonClicked = () => {
      const isOnHold =
        projectDetails.value?.projectStatusWFDTO?.projectStatusCode ===
        "ST_ON_HOLD";
      const confirmationMessage = isOnHold
        ? "Removing the hold will place the project in scoping status. Are you sure you want to proceed? Y/N"
        : "Are you sure you want to place the project On Hold?";
      holdUnholdProjectDialog.value
        .open(
          "Confirmation",

          [confirmationMessage],

          "400"
        )
        .then((resolve) => {
          if (resolve) {
            const newStatusCode =
              projectDetails?.value?.projectStatusWFDTO?.projectStatusCode ===
              "ST_ON_HOLD"
                ? "ST_SCOPING"
                : "ST_ON_HOLD";
            axios
              .put(
                CONFIG_ENDPOINT.HOLD_UNHOLD_MPMS_PROJECT +
                  projectId.value +
                  "?userID=" +
                  user.value.sub +
                  "&statusCode=" +
                  newStatusCode
              )
              .then((response) => {
                getData();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    };

    const reOpenProjectButtonClicked = () => {
      const confirmationMessage =
        "Are you sure you want to reopen the project?";
      holdUnholdProjectDialog.value
        .open("Confirmation", [confirmationMessage], "400")
        .then((resolve) => {
          if (resolve) {
            const newStatusCode = "ST_SCOPING";
            axios
              .put(
                CONFIG_ENDPOINT.RE_OPEN_PROJECT +
                  projectId.value +
                  "?userID=" +
                  user.value.sub +
                  "&statusCode=" +
                  newStatusCode
              )
              .then((response) => {
                userRefreshDataStore.setRefreshData(true);
                getData();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    };
    const setCurrentTabValue = (value) => {
      console.log("setCurrentTabValue", value);
      currentTab.value = value;
    };
    const getCurrentTabValue = computed({
      get: () => {
        let value = "";
        switch (currentTab.value) {
          case "projectInformation":
            value = "PRJINFHELP";
            break;
          case "locationInformation":
            value = "PRJLOCHELP";
            break;
          case "gradeCrossingAndBridges":
            value = "PRJGCHELP";
            break;
          case "miletones":
            value = "PRJMLHELP";
            break;
          case "costAndFunding":
            value = "PRJINFHELP";
            break;
          case "designAndConstruction":
            value = "PRJDCHELP";
            break;
          case "projectFlaggerNeeds":
            value = "PRJFLGHLP";
            break;
          case "addRailroads":
            value = "";
            break;
          case "safetyMilestones":
            value = "SFTMLHELP";
            break;
          /* Safety Milestones Documents */
          case "documents":
            value = "SFTMLDCHLP";
            break;
          case "Scoping":
            value = "SFTMLHELP";
            break;
          case "project-involvement":
            value = "PRJINVHELP";
            break;
          case "railroad-Estimates":
            value = "RRESTHLP";
            break;
          case "parties-of-record":
            value = "MNGPARREC";
            break;
          case "workflow-notifications":
            value = "PRJWRKHLP";
            break;
          case "puc-communications":
            value = "PUCCUMHELP";
            break;
          case "railroad-plan-review":
            value = "RRPLANHELP";
            break;
          case "project-team":
            value = "PRJTEAMHLP";
            break;
          case "railroad-project-team":
            value = "";
            break;
          case "railroad-certification":
            value = "RRCERTHELP";
            break;
          case "railroad-work-summary":
            value = "RRWRKSMHLP";
            break;
          case "project-history":
            value = "";
            break;
          case "project-gradecrossing-inventory-updates":
            value = "";
            break;
          case "contractor-bid":
            value = "";
            break;
          case "project-document":
            value = "";
            break;
          case "project-followers":
            value = "";
            break;
          default:
            value = "PRJHEADHLP";
        }
        return value;
      },
    });

    return {
      currentTab,
      setCurrentTabValue,
      getCurrentTabValue,
      projectDetails,
      tab,
      projectId,
      menuTitle,
      menuItems,
      getIdentifierIcon,
      projectIdentifierIcons,
      updateData,
      safetyProjectFlag,
      highwayProjectFlag,
      toTitleCase,
      user,
      isAuthenticated,
      BannerUpdate,
      SuccessBannerData,
      hideSuccessBanner,
      hideErrorBannerMessage,
      ErrorBannerData,
      isPucApprovalRequired,
      open,
      ChangeProjectStatusDialog,
      changeStatusButtonClicked,
      followUnfollowButtonClicked,
      holdUnHoldButtonClicked,
      holdUnholdProjectDialog,
      isChangeStatusAccess,
      isHoldButtonAccess,
      isFollowUnfollowButtonAccess,
      reOpenProjectButtonClicked,
      reOpenProjectAccessCheck,
      isReopenButtonAccess,
      followPageRefreshed,
      refreshOnFollowButtonClick,
    };
  },
};
