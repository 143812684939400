import useDateField from "@/composables/dateField.js";import useIconsFunctions from "@/composables/projectIdentifiersIcons";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import DataTable from "@/modules/shared/components/DataTable.vue";
import { useUserStore } from "@/stores/userStore";
import axios from "axios";
import { computed, onMounted, ref } from "vue";

const recentlyAccessedProjects = ref([]);
const loading = ref(true);
const headers = ref([
  {
    title: "",
    filter: [],
    filterType: "multi-select",
    filterSort: "text",
    sortType: "text",
    key: "projectIdentifier",
    columnType: "icon",
    icon: "projectIdentifierIcon",
    sortable: false,
  },
  {
    title: "MPMS#",
    filter: null,
    filterType: "textbox",
    filterWith: "startWith",
    sortType: "number",
    key: "mpmsNumber",
    isLink: true,
  },
  {
    title: "Project Title",
    filter: [],
    filterType: "multi-select",
    filterSort: "text",
    sortType: "text",
    key: "projectTitle",
    width: "25%",
  },
  {
    title: "SR",
    filter: [],
    filterType: "multi-select",
    filterSort: "text",
    sortType: "text",
    key: "stateRoute",
  },
  {
    title: "Section",
    filter: [],
    filterType: "multi-select",
    filterSort: "text",
    sortType: "text",
    key: "srSection",
  },
  {
    title: "District",
    filter: [],
    filterType: "multi-select",
    filterSort: "text",
    sortType: "text",
    key: "district",
  },
  {
    title: "County ",
    filter: [],
    filterType: "auto-complete",
    filterSort: "text",
    sortType: "text",
    key: "county",
  },
  {
    title: "Status ",
    filter: [],
    filterType: "multi-select",
    filterSort: "text",
    sortType: "text",
    key: "projectStatus",
  },
  {
    title: "Let Date",
    filter: [],
    filterType: "multi-select",
    filterSort: "text",
    sortType: "date",
    key: "letDate",
  },
  {
    title: "GC Coordinator",
    filter: [],
    filterType: "multi-select",
    filterSort: "text",
    sortType: "text",
    key: "gcCoordinator",
  },
]);

export default {
  name: "RecentProjects",
  components: { DataTable },
  setup() {
    const { getIdentifierIcon } = useIconsFunctions();
    const { getFormattedDateStringNoTime } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const userStore = useUserStore();
    const user = computed(() => userStore.loggedInUser);

    onMounted(async () => {
      await fetchData();
    });
    async function fetchData() {
      loading.value = true;
      axios
        .get(
          CONFIG_ENDPOINT.GCMS_MAIN_RECENT_ACCESSED_PROJECTS +
            "?userID=" +
            user.value.sub
        )
        .then((response) => {
          setRecentProjectsDetails(response);
        });
    }
    const setRecentProjectsDetails = (response) => {
      loading.value = false;
      recentlyAccessedProjects.value = response.data.data;
      recentlyAccessedProjects.value.forEach((item) => {
        let icons = "";
        item?.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.projectIdentifierIcon = icons;
        item.letDate = getDate(item.letDate);
        headers.value.forEach((header, index) => {
          headers.value[index] = {
            ...header,
            sortable: !checkFieldDisabled(header.key),
          };
        });
      });
    };
    const checkFieldDisabled = (key) => {
      return true;
    };
    return { recentlyAccessedProjects, loading, headers };
  },
};
