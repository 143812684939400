
<template>
  <div class="expanderOverLay">
    <ParentSubsidaries></ParentSubsidaries>
  </div>
</template>
<script>
import ParentSubsidaries from "@/modules/gcmsmain/admin/submenu/components/ParentSubsidaries.vue";
export default {
  name: "ParentSubsidariesView",
  components: {
    ParentSubsidaries: ParentSubsidaries,
  },
};
</script>
