<template>
  <v-row>
    <v-col class="ml-20">
      <a class="color-blue underline cursor-pointer" @click="$router.back()">
        &lt; Return to Manage Parent-Subsidiaries List Page
      </a>
    </v-col>
    <v-col cols="6" class="page-title">
      <h1>{{ isAdd ? "Add" : "Edit" }} Parent and Subsidiary Relationship</h1>
    </v-col>
    <v-col> </v-col>
  </v-row>
  <div>
    <v-form v-model="isFormValid" class="projectInvolvement">
      <div class="w-100">
        <v-row class="m-20">
          <v-col cols="2">
            <b class="textColumn required"
              ><span>Parent Company: </span></b
            ></v-col
          >
          <v-col cols="3">
            <v-autocomplete
              :no-data-text="AUTO_COMPLETE_NO_DATA"
              class="select-fields"
              outlined
              :disabled="!isUserEditParentSubsidiaryAccess.update"
              v-model="parentSubsidaryForm.parentCompany"
              :items="parentSubsidaryLookup.parentCompany"
              v-model:search="parentSubsidaryQuery.parentCompany"
              @update:search="autoComplete('parentCompany', $event)"
              @update:modelValue="valueSelected('parentCompany')"
              item-value="railroadCompanyId"
              item-title="name"
              :rules="[requiredRule]"
              :open-on-clear="true"
              :clearable="true"
              @click:clear="clearParentCompany($event)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2"></v-col>
          <v-col cols="2">
            <b class="textColumn required"><span>Subsidiary: </span></b></v-col
          >
          <v-col cols="3">
            <v-autocomplete
              :no-data-text="AUTO_COMPLETE_NO_DATA"
              class="select-fields"
              outlined
              :disabled="!isUserEditParentSubsidiaryAccess.update"
              v-model="parentSubsidaryForm.subsidiary"
              :items="parentSubsidaryLookup.subsidiary"
              v-model:search="parentSubsidaryQuery.subsidiary"
              @update:search="autoComplete('subsidiary', $event)"
              @update:modelValue="valueSelected('subsidiary')"
              item-value="railroadCompanyId"
              item-title="name"
              :rules="[requiredRule]"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="m-20">
          <v-col cols="2">
            <b class="textColumn required"
              ><span
                >Parent Users Authorized to Work on Behalf of Subsidiary:
              </span></b
            ></v-col
          >
          <v-col cols="3">
            <p v-if="!parentSubsidaryLookup.subsidiaryUsers?.length">
              {{ parentSubsidaryForm.parentCompany ? "No Users Found" : "" }}
            </p>
            <v-container fluid style="padding: 0">
              <v-checkbox
                v-if="parentSubsidaryLookup.subsidiaryUsers?.length"
                :disabled="!isUserEditParentSubsidiaryAccess.update"
                v-model="parentSubsidaryForm.isAllSelected"
                @change="selectAllUserUpdate($event)"
                label="All"
              ></v-checkbox>
              <v-card style="max-height: 300px; overflow-y: scroll">
                <v-checkbox
                  v-for="(user, index) in parentSubsidaryLookup.subsidiaryUsers"
                  :key="index"
                  :disabled="!isUserEditParentSubsidiaryAccess.update"
                  v-model="parentSubsidaryForm.subsidiaryUsers"
                  :value="user?.userId"
                  :label="user?.userName"
                ></v-checkbox>
              </v-card>
            </v-container>
          </v-col>
          <v-col cols="2"></v-col>
          <v-col cols="2">
            <b class="textColumn"
              ><span>Subsidiary Project Team Default:</span></b
            ></v-col
          >
          <v-col cols="3">
            <v-select
              class="select-fields"
              :items="parentSubsidaryLookup.projectTeamDefault"
              item-title="userName"
              item-value="userId"
              :disabled="!isUserEditParentSubsidiaryAccess.update"
              v-model="parentSubsidaryForm.projectTeamDefault"
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>

  <div class="w-100 d-flex justify-content-end mt-20 mb-20">
    <PrimaryButton
      :buttonText="'Save'"
      :disabled="
        !parentSubsidaryForm.parentCompany ||
        !parentSubsidaryForm.subsidiary ||
        !parentSubsidaryForm.subsidiaryUsers?.length ||
        !isUserEditParentSubsidiaryAccess.update
      "
      @buttonClicked="saveClicked"
    ></PrimaryButton>
    <SecondaryButton
      :buttonText="'Cancel'"
      class="ml-2"
      @buttonClicked="cancelSave"
    ></SecondaryButton>
  </div>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
  <script>
import AddEditParentSubsidaries from "@/modules/gcmsmain/admin/submenu/scripts/AddEditParentSubsidaries.js";
export default AddEditParentSubsidaries;
</script>
  
  <style>
@import "@/assets/css/style.scss";
@import "@/modules/manageproject/assets/railroadEstimates.scss";
.selected-projects-card {
  max-height: 150px;
  overflow-y: scroll;
}
.projectInvolvement .selected-projects-card .v-input__details {
  display: none !important;
}
</style>
  