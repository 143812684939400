<template>
  <v-card class="p-64 v-card-text">
    <OnlineHelpPOC v-if="showHelp" :helpKey="'DASH_HELP'"></OnlineHelpPOC>

    <div class="allProjectsHeading mb-20">Manage Parent-Subsidiaries</div>
    <div v-if="ErrorBannerData">
      <BannerMessage
        v-if="
          ErrorBannerData.bannerType === 'error' && ErrorBannerData.showBanner
        "
        :errorBanner="true"
        :message="ErrorBannerData.bannerMessage"
        :hasList="ErrorBannerData.hasList"
        :errorList="ErrorBannerData.errorList"
        @hideBanner="hideErrorBannerMessage"
      ></BannerMessage>
    </div>
    <v-card class="p-64">
      <FeatureTable
        :headers="headers"
        :items="railroadEstimatesList"
        :linkedPagination="false"
        :hidePagination="true"
        :hideFilter="true"
        :itemsPerPage="paginationData?.items"
        :totalItems="paginationData?.total"
        @sortUpdate="sortUpdate"
        @editButtonClicked="editButtonClicked"
        @deleteButtonClicked="deleteButtonClicked"
        tableName="railroadEstimates"
      >
      </FeatureTable>
      <div class="d-flex justify-content-start m-20">
        <PrimaryButton
          @buttonClicked="addButtonClicked"
          :buttonText="'ADD PARENT SUBSIDIARY'"
          :disabled="!isUserEditParentSubsidiaryAccess.update"
        ></PrimaryButton>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import ParentSubsidaries from "@/modules/gcmsmain/admin/submenu/scripts/ParentSubsidaries.js";
export default ParentSubsidaries;
</script>
<style>
.container {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  padding-top: 20px;
}
</style>
